import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "../pages/Privacy";
import CommonLayout from "./CommonLayout";
import Terms from "../pages/Terms";
import Chat from "../pages/Chat";
import Home from "../pages/Home";
import Pricing from "../pages/Pricing";
import CardForm from "../pages/SavedCard";
import CardDetail from "../pages/CardDetail";
import CancellationPolicy from "../pages/CancellationPolicy";
import ErrorPage from "../pages/ErrorPage";
import Cancellation from '../pages/cancellation';
import UpdateHome from '../pages/UpdateHome';

const Routing = () => (
  <Router>
    <Routes>
      <Route path="/home" element={<CommonLayout>{<Home />}</CommonLayout>}></Route>
      <Route
        path="/pricing"
        element={<CommonLayout>{<Pricing />}</CommonLayout>}
      ></Route>
      <Route
        path="/legal-advisory"
        element={<CommonLayout>{<Chat />}</CommonLayout>}
      ></Route>
      {/* <Route path="/login" element={<Login />}></Route> */}
      <Route
        path="/privacy"
        element={<CommonLayout>{<Privacy />}</CommonLayout>}
      ></Route>
      <Route
        path="/terms"
        element={<CommonLayout>{<Terms />}</CommonLayout>}
      ></Route>
      <Route
        path="/cancellation-policy"
        element={<CommonLayout>{<CancellationPolicy />}</CommonLayout>}
      ></Route>
      <Route
        path="/home"
        element={<CommonLayout>{<Home />}</CommonLayout>}
      ></Route>
      <Route
        path="/savedcard"
        element={<CommonLayout>{<CardForm />}</CommonLayout>}
      ></Route>
      <Route
        path="/card_details"
        element={<CommonLayout>{<CardDetail />}</CommonLayout>}
      ></Route>
      <Route
        exact="true" path="/*"
        element={<ErrorPage />}
      ></Route>
      <Route
        path="/Cancellation-policy"
        element={<Cancellation />}
      ></Route>
       <Route
        path="/"
        element={<CommonLayout>{<UpdateHome />}</CommonLayout>}
      ></Route>
    </Routes>
  </Router>
);

export default Routing;

export const openAiApiUrl = "https://api.openai.com/v1/chat/completions"
export const openAiImageVariationUrl = "https://api.openai.com/v1/images/variations"

export const OPENAI_SECRET_API_KEY = "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"

export const OPENAI_ORGANIZATION_ID = "org-EKNzI3W6Lfpd0T7MOpXjFmU2"

export const GOOGLE_CLIENT_ID = "279502010852-620btdovam7pftovu7bi1ol91jrcbihv.apps.googleusercontent.com"

// export const STRIPE_KEY = "sk_test_cJr4PLKlD8HJ0RK0pGipdsua"  //(live)

export const STRIPE_KEY ="sk_test_51NQmXOCh9FqMhX5amIfdL81WmqZnH9uNUzkfLH0KaUYULN2HPCSX3o8dLFlBKXpKkzvGSWNDZh15tCSRED5QPgpl006GrOI2WI"  //(staging)
 

export const APP_URL = "aiattorney.biz"   // live
// export const APP_URL = "staging.aiattorney.biz" // staging
export const CONTACT_US_EMAIL = "info@aiattorney.biz"

// export const DEFAULT_PLAN_ID = "price_1NNVvTCQztEsS9y5rlXoXpq9"  //(live)
// export const PREMIUM_PLAN_ID = "price_1NNVOECQztEsS9y5UVVSSTuh" // (live)

export const DEFAULT_PLAN_ID = "price_1NQmlqCh9FqMhX5afkeFOzY6"  //(staging)
export const PREMIUM_PLAN_ID = "price_1NQmnUCh9FqMhX5aERxcYBji" // (staging)

// export const baseUrl = "http://127.0.0.1:8000"  // local
export const baseUrl = "https://api.aiattorney.biz"  // live
// export const baseUrl = "https://staging-api.aiattorney.biz"  // staging

export const websocket = "wss://api.aiattorney.biz/ws"  // live
// export const websocket = "wss://staging-api.aiattorney.biz/ws"  // staging

export const sampleQuestions =  [
    ["👩‍💼", "What are my rights as an employee?"],
    ["💔", "How do I file for divorce and what are the requirements?"],
    ["📑", "How do I create a will or trust?"],
    ["🚑", "What should I do if I've been injured in an accident?"],
]
// make sure that question is at index 1 in each array

export const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// export const stagingpalID= "prod_ODDCAMsOAlI6NR"
// export const livePlanID ="prod_Nu4Ic73wsNzWOm"

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Container, Dropdown, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";
import axios from "axios";
import { setItem } from "../utils/localStorage";
import { openAiService } from "../../services/openai.services";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { DEFAULT_PLAN_ID } from '../../config/config';
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const globalData = useContext(context);
  const [user, setUser] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [isUserSigningUp, setIsUserSigningUp] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [googleUserData, setGoogleUserData] = useState({})
  const [userDetail, setUserDetail]=useState("")
  const [errors, setErrors] = useState({
    termsError: false,
    privacyError: false,
  });
  const tAndC = <span style={{'fontSize': '14px'}}>Accept Terms and Conditions <a target="_blank" href="/terms">Click here for T&Cs.</a></span>
  const privacyStatement = <span style={{'fontSize': '14px'}}>Review and accept the Privacy Policy <a target="_blank" href="/privacy">Click here for the Privacy Policy.</a></span>
  useEffect(()=>{
    if(globalData.triggerLogin){
      login()
      globalData.setTriggerLogin(false)
    }
  }, [globalData.triggerLogin])

  // useEffect(() =>{
  //   userDetailapi()
  // },[])

  useEffect(() => {
    if (localStorage.getItem("token")) {
      userDetailapi()
    } else if(location.pathname === "/" || location.pathname.includes("/pricing") || location.pathname.includes("/terms") || location.pathname.includes("/privacy") || location.pathname.includes("/cancellation-policy") || location.pathname.includes("/Cancellation-policy"))
    {

    }else if(location.pathname === "/updated-home"){
     navigate("/updated-home");
    }
    else {
      navigate("/");
    }
  }, [location.pathname]);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    onNonOAuthError: (error) => {
      if(error.type === "popup_closed"){
        setIsUserSigningUp(false)
      }
    }
  });

  // useEffect(() =>{
  //   if(location.pathname.includes("/Cancellation-policy")){
      
  //   }
  // })
  useEffect(() => {
    if (user) {
      getGoogleUser(user) 
    }
  }, [user]);
  // for fetching user details for login/signup
  function getGoogleUser(user){
    axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: "application/json",
        },
      }).then((res) => {
      if (res.data.id) {
        let data = {
          email: res.data.email,
          firstname: res.data.given_name,
          lastname: res.data.family_name || "",
          socialid: res.data.id,
          picture: res.data.picture || "",
        };
        setGoogleUserData(data)
        if(isUserSigningUp){
          data.isTermsConditionsChecked = termsCheck ? 1 : 0
          data.isPrivacyPolicyChecked = privacyCheck ? 1 : 0
        }
        setShowLoader(true);
        registerUserApi(data);
      }
    }).catch((err) => console.log(err));
  }

  function userDetailapi(){
    openAiService.userdetails().then((res) =>{
      setUserDetail(res?.data?.data)
      if(res?.data?.data?.subscription_id  == null){
        localStorage.removeItem("plan")
      }
    })
    .catch((Error) =>{
      console.log("error",Error)
      localStorage.removeItem("plan")
    })
  }
  
  function registerUserApi(data){
    openAiService.registerUser(data).then((res) => {
        if (res.data?.token) {
          setItem("user", res.data);
          setItem("token", res.data.token);
          globalData.setToken(res.data.token);
            // if(res.data.subscription_plan){
              getSubscription(res.data.subscription_plan)
            // }
            userDetailapi()
            globalData.setTermsAndPrivacyModal(false)
        }
        setShowLoader(false);
        globalData.setSignUpModal(false);
      }).catch((err) => {
        globalData.setSignUpModal(false);
        if(!err?.response?.data?.agreed){
          setErrors({})
          globalData.setTermsAndPrivacyModal(true)
        }else{
          Swal.fire("Error", err.response?.data?.error || "Something went wrong.", "error")
        }
        setShowLoader(false);
      });

  }
  function getSubscription(subId){
    // if user has no plan then there will be no subId
    if(subId){
      openAiService.getSubscription(subId).then(res=>{
        if(res.data?.plan.active){
          if(res.data?.plan.id === DEFAULT_PLAN_ID){}else{
            globalData.setCurrentPlan(res.data.plan)
            localStorage.setItem("plan", JSON.stringify(res.data.plan))
          }
        }
        setShowLoader(false);
        showLoginSuccess()
      }).catch(err=>{
        localStorage.removeItem("plan")
        setShowLoader(false);
        window.location.href = "/legal-advisory";
      })
    }else{
      showLoginSuccess()
    }
  }
  const showLoginSuccess = () => {
    Swal.fire("success", "Login successful", "success").then(()=>{
      if(globalData.loginVia === "PRICING"){
        globalData.setScrollToPricing(true)
        globalData.setLoginVia("");
        localStorage.setItem("scPrice", true)
        window.location.href = "/"
      } else if(globalData.loginVia === "OTHER_FEATURES"){
        globalData.setShowPopupModal(true)
        globalData.setLoginVia("")
        localStorage.setItem("shPop", true)
        window.location.href = "/legal-advisory"
      }else{
        window.location.href = "/legal-advisory";
      }
    })
  }
  const handleSignUpSubmit = () => {
    let formValidated = true
    if(!termsCheck){
      formValidated = false
      setErrors(prevObj=>{
        return({
          ...prevObj,
          termsError: true
        })
      })
    }
    if(!privacyCheck){
      formValidated = false
      setErrors(prevObj=>{
        return({
          ...prevObj,
          privacyError: true
        })
      })
    }

    if(formValidated){
      setIsUserSigningUp(true)
      login()
    }
  }
  const logOut = () => {
    localStorage.clear();
    googleLogout();
    navigate("/");
  };
  return (
    <>
      {/* {
        showLoader &&
        <div className="main-loader">
        <div className="loader">
          <RotatingLines width="100" strokeColor="#111" />
        </div>
        </div>
      } */}
      {
        showLoader &&
       <div className="main-loader">
       <div className="loader3">
            <span></span>
            <span></span>
        </div>
        </div>      
      }

      <section className="header">
        <Container>
          <div className="main-chat-header">
            <div
              className="header-left"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img
                src={require("../../assests/images/ai-lawyer-logo.png")}
                alt="img"
              />
            </div>
            <div className="header-right">
              <div className="activate-pro-faq">
                  <>
                  <div className="header-top-right">
                    <p className={location.pathname =="/legal-advisory" ? "active":""} style={{'cursor': 'pointer'}} 
                      onClick={() => {
                        if(localStorage.getItem("user")){
                          if(location.pathname !== "/legal-advisory"){
                            navigate("/legal-advisory")
                          }
                        }else{
                          globalData.setTriggerLogin(true)
                        }
                      }}> 
                      Legal Advisor
                    </p>
                  </div>

                  <div className="header-top-right">
                    <p style={{'cursor': 'pointer'}} onClick={() => {
                        globalData.setShowPopupModal(true)
                        if(localStorage.getItem("user")){
                          if(location.pathname !== "/legal-advisory"){
                            navigate("/legal-advisory")
                          }
                        }else{
                          globalData.setLoginVia("OTHER_FEATURES")
                          globalData.setTriggerLogin(true)
                        }
                      }}>
                      Other Features
                    </p>
                  </div>
                  </>
               
                <div className="header-top-right">
                  <p style={{'cursor': 'pointer'}} onClick={() => {
                      globalData.setScrollToPricing(true)
                      navigate("/", {state:{setScrollToPrice: true}})
                    }}>
                    Pricing
                  </p>
                </div>
              </div>
              {localStorage.getItem("user") ? (
                <div className="email-dropdown">
                  <div className="email-top-area">
                    {localStorage.getItem("user") ? (
                      <>
                        <h6>
                          {JSON.parse(localStorage.getItem("user")).firstname}{" "}
                          {JSON.parse(localStorage.getItem("user")).lastname || ""}
                        </h6>
                        <p>{JSON.parse(localStorage.getItem("user")).email}</p>
                      </>
                    ) : null}
                  </div>
                  <div className="dropdown-top">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {JSON.parse(localStorage.getItem("user"))?.firstname?.substring(1, 0)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={logOut}><i class="fa fa-sign-out" aria-hidden="true"></i>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div className="header-top-left">
                  <p onClick={() => {
                    login()
                    }}>
                  <img
                      className="google-logo-style"
                      src={require("../../assests/images/google-logo.png")}
                      alt="google"
                    ></img>
                  Login with google
                  </p>
                </div>
              )}
            </div>
                                  
            <div className="dropdown-top mobile-tab">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                      ☰
                      </Dropdown.Toggle>
                      <Dropdown.Menu>

                  <div className="header-right">
                          <div className="activate-pro-faq">
              </div>
                   

                    <div className="activate-pro-faq">
                      {localStorage.getItem("user") &&
                        <>
                          <div className="header-top-right">
                            <p style={{ 'cursor': 'pointer' }}
                              onClick={() => {
                                if (location.pathname !== "/legal-advisory") {
                                  navigate("/legal-advisory");
                                }
                              }}>
                              Legal Advisor
                            </p>
                          </div>

                          <div className="header-top-right">
                            <p style={{ 'cursor': 'pointer' }} onClick={() => {
                              globalData.setShowPopupModal(true)
                              if (location.pathname !== "/legal-advisory") {
                                navigate("/legal-advisory")
                              }
                            }}>
                              Other Features
                            </p>
                          </div>
                        </>
                      }
                      
                      <div className="header-top-right">
                        <p style={{ 'cursor': 'pointer' }} onClick={() => {
                          globalData.setScrollToPricing(true)
                          navigate("/")
                        }}>
                          Pricing
                        </p>
                      </div>
                    
                    </div>
               {localStorage.getItem("user") ? (
                      <div className="email-dropdown">
                        <div className="email-top-area">
                          {localStorage.getItem("user") ? (
                            <>
                              <h6>
                                {JSON.parse(localStorage.getItem("user")).firstname}{" "}
                                {JSON.parse(localStorage.getItem("user")).lastname || ""}
                              </h6>
                              <p>{JSON.parse(localStorage.getItem("user")).email}</p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div className="header-top-left">
                        <p onClick={() => {
                          login()
                        }}>
                          <img
                            className="google-logo-style"
                            src={require("../../assests/images/google-logo.png")}
                            alt="google"
                          ></img>
                          Login with google
                        </p>
                      </div>
                    )}
                      {localStorage.getItem("user")
                      ?
                      <div className="dropdown-top">
                        <button onClick={logOut}>Logout</button>
                      </div>
                      :null}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Container>
      </section>

      <Modal
        className="terms-condition-modal-area-area"
        show={globalData.termsAndPrivacyModal}
        // backdrop="static"
        onHide={() => {
          globalData.setTermsAndPrivacyModal(false);
          setTermsCheck(false)
          setPrivacyCheck(false)
          setErrors({})
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Sign Up</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="google-login-btn">
            <Form>
              <div className="row">
                <div className="login-form-box terms-condition-box-area">
                  <h5 className="mb-4">To utilize AiAttorney, you are required to agree to the following terms:</h5>
                  <Form.Check id="t_c" className={errors.termsError ? "mb-3 signup-error terms-label" : "mb-3 terms-label"} type="checkbox" disabled={isUserSigningUp} checked={termsCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setErrors(obj => {
                          return ({
                            ...obj,
                            termsError: false
                          })
                        })
                      }
                      setTermsCheck(e.target.checked)
                    }}
                    label={tAndC} />
                  <Form.Check id="pe" className={errors.privacyError ? "mb-3 signup-error terms-label" : "mb-3 terms-label"} type="checkbox" disabled={isUserSigningUp} checked={privacyCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setErrors(obj => {
                          return ({
                            ...obj,
                            privacyError: false
                          })
                        })
                      }
                      setPrivacyCheck(e.target.checked)
                    }}
                    label={privacyStatement} />
                  <Button
                    type="button"
                    variant="unset"
                    className="w-75"
                    disabled={!termsCheck || !privacyCheck}
                    onClick={()=>{
                      let data = googleUserData
                      data.isTermsConditionsChecked = termsCheck ? 1 : 0
                      data.isPrivacyPolicyChecked = privacyCheck ? 1 : 0
                      setGoogleUserData(data)
                      registerUserApi(data)
                    }}
                    style={{ margin: "12px 12px" }}
                  >
                    <img
                      className="google-logo-style"
                      src={require("../../assests/images/google-logo.png")}
                      alt="google"
                    ></img>
                    Continue
                    {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                  </Button></div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;

import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Form,
  CloseButton,
} from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_PLAN_ID,
  PREMIUM_PLAN_ID,
  sampleQuestions,
} from "../../config/config";
import Swal from "sweetalert2";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import { EMAILREGEX } from "../../config/config";
import { capitalizeFirstLetter } from "../utils/localStorage";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useCallback } from "react";
import { baseUrl, websocket } from "../../config/config";
import Pagination from "react-js-pagination";
import PhoneInput from "react-phone-input-2";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

// import ReCAPTCHA from "react-google-recaptcha";

const Chat = () => {
  const cursorPosition = 0;

  const userId = JSON.parse(localStorage.getItem("user"))?.user_id;
  const navigate = useNavigate();
  const globalData = useContext(context);
  const globalDataRef = useRef();
  globalDataRef.current = globalData;
  const ct = require("countries-and-timezones");

  const [socketUrl, setSocketUrl] = useState(null);
  // const URL = `wss://api.aiattorney.biz/ws/chat/${userId}`;  // live
  // const URL = `wss://staging-api.aiattorney.biz/ws/chat/${userId}`; // staging

  const { sendMessage, sendJsonMessage, lastMessage, readyState } =
    useWebSocket(socketUrl);

  const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const COUNTRY_CODE = timezone.countries[0];

  const webSocketRef = useRef(null);
  const [totalRecord, setTotalRecords] = useState();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [downloadIcon, setDownloadIcon] = useState(true);
  const [queryString, setQueryString] = useState("");
  const [lastQuestion, setLastQuestion] = useState("");
  const [regenerate, setRegenerate] = useState(false);
  const [chat, setChat] = useState([]);
  const [chatResponse, setChatResponse] = useState("");
  const [mainLoader, setMainLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [activeChat, setActiveChat] = useState({});
  const [openaiId, setOpenaiId] = useState("");
  const [chatHistoryObj, setChatHistoryObj] = useState("");
  const [isBookmarked, setIsBookmarked] = useState(0);
  const [history, setHistory] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [showChatResponse, setshowChatResponse] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [chatTriggered, setChatTriggered] = useState(false);
  const [trigerScroll, setTriggerScroll] = useState(false);
  const [elementHeight, setElementHeight] = useState();
  const [pdfFile, setPdfFile] = useState([]);
  const [pdfFile0, setPdfFile0] = useState();
  const [pdfFile1, setPdfFile1] = useState();
  const [showPdfPrompt, setShowPdfPrompt] = useState(false);
  const [showComparePdfPrompt, setCompareShowPdfPrompt] = useState(false);
  const [upGradePlanPopupModal, setUpgradePlanPopupModal] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [keepScrollingToBottom, setKeepScrollingToBottom] = useState(false);
  const [uploadedPdf, setUploadedPdf] = useState(null);
  const [contractField, setcontractField] = useState({
    identifyPartie: "",
    definePurpose: "",
    outlineObligation: "",
    determineConsequences: "",
    specifyDates: "",
    identifyjurisdiction: "",
    signingMethod: "",
  });

  let bookmarkDisabled = true;
  const generatedAnswer = useRef("");
  const [contactUsFields, setContactUsFields] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const { transcript, resetTranscript, browserSupportsContinuousListening } =
    useSpeechRecognition();
  const bottomRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    connectWithWebsocket();
  }, []);

  const connectWithWebsocket = (currentChat = activeChat) => {
    if (
      currentChat?.prompt_type == "compare_agreement" ||
      currentChat?.prompt_type == "summary_agreement"
    ) {
      setSocketUrl(`${websocket}/pdf/${currentChat?.openai_id}`);
    } else {
      setSocketUrl(`${websocket}/chat/${userId}`);
    }
  };

  function onChange(value) {
    setIsCaptchaValue(value);
  }

  useEffect(() => {
    setQueryString(transcript);

    const textInputEl = inputRef.current;

    textInputEl.scrollTop = textInputEl.scrollHeight;
  }, [transcript]);

  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("listening");
    // SpeechRecognition.startListening({
    //   continuous: true,
    // });

    if (browserSupportsContinuousListening) {
      SpeechRecognition.startListening({ continuous: true });
    }

    resetTranscript();

    setTimeout(stopHandle, 120000);
  };

  const stopHandle = () => {
    setIsListening(false);
    setDuration(2 * 60);
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
    // resetTranscript();
  };

  const handleReset = () => {
    setQueryString("");
    stopHandle();
    resetTranscript();
  };

  const handleContactUsSubmit = (e) => {
    e.preventDefault();
    let formValidated = true;
    if (contactUsFields.firstName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          firstName: true,
        };
      });
    }

    if (contactUsFields.phone.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    } else if (contactUsFields.phone.trim().length < 10) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (contactUsFields.email.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    } else if (!EMAILREGEX.test(contactUsFields.email)) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    }
    if (contactUsFields.message.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          message: true,
        };
      });
    }
    if (formValidated) {
      let data = {
        first_name: contactUsFields.firstName,
        last_name: contactUsFields.lastName,
        email: contactUsFields.email,
        message: contactUsFields.message,
        phone: "+" + contactUsFields.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      openAiService
        .contactUs(data)
        .then((res) => {
          Swal.fire(
            "Success",
            "Thank you for submitting your request. We are reviewing it and will respond soon!",
            "success"
          ).then(() => {
            globalDataRef.current.setShowContactUsModal(false);
            setContactUsFields({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
              phone: "",
            });
          });
        })
        .catch((err) => {
          Swal.fire("Error", "Something went wrong.", "error");
        });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const [stopChatWritting, setStopChatWritting] = useState(true);
  const stopChatWrittingRef = useRef();
  stopChatWrittingRef.current = stopChatWritting;
  const [restartPdf, setRestartPdf] = useState();
  const [duration, setDuration] = useState(2 * 60);

  useEffect(() => {
    let interval;
    if (isListening) {
      interval = setInterval(() => {
        setDuration((prevDuration) => prevDuration - 1);
        if (duration <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isListening]);
  // socket connection
  useEffect(() => {
    if (lastMessage !== null) {
      setShowLoader(false);
      let messageResponse = JSON.parse(lastMessage.data);
      if (
        messageResponse?.stream === false &&
        messageResponse?.message === "Subscription expired or trail ended."
      ) {
        Swal.fire(
          "Warning",
          "Your subscription has expired, or your trial period has come to an end.",
          "warning"
        );
      }
      if (!messageResponse.stream) {
        setshowChatResponse(false);
        setChatTriggered(false);
        let answer = generatedAnswer.current;
        setChat((prevObj) => [...prevObj, { type: "answer", text: answer }]);
        generatedAnswer.current = "";
        let elm = document.getElementById("chat-response-id");
        if (elm) {
          elm.innerHTML = "";
        }
        if (localStorage.getItem("plan")) {
          getHistory();
        }
      } else {
        if (stopChatWrittingRef.current) {
          setOpenaiId(messageResponse.openai_id);
          let elm = document.getElementById("chat-response-id");
          if (elm) {
            elm.innerHTML = messageResponse.message;
          }
          generatedAnswer.current = messageResponse.message;
          if (
            element.scrollHeight - (element.scrollTop + element.clientHeight) >
            200
          ) {
            setKeepScrollingToBottom(false);
          } else {
            setKeepScrollingToBottom(true);
          }
          setElementHeight(element.scrollHeight);
        }
      }
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const [pageNo, setPageNo] = useState(1);
  const [totalHistoryRecords, setTotalHistoryRecords] = useState(0);
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getHistory();
    }
  }, [pageNo]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getBookmarklist();
    }
  }, [page]);

  // when question response is recieved this useffect is triggered to start typing animation effect
  useEffect(() => {
    if (chatTriggered) {
      setTimeout(() => {
        typeWriterEffect();
      }, 300);
    }
  }, [chatResponse]);

  const element = document.getElementById("chatbox-body-id");
  const leftChatElement = document.getElementById("left-chat-boxes-id");
  // History api call when scrolled to bottom.
  leftChatElement?.addEventListener("scroll", onBottomScroll, false);

  function onBottomScroll() {
    if (
      leftChatElement?.scrollHeight ===
      leftChatElement?.scrollTop + leftChatElement?.clientHeight
    ) {
      if (totalHistoryRecords > history.length) {
        setPageNo(pageNo + 1);
      }
    }
  }

  function handlePageChange(pageNumber, tabType) {
    if (tabType == "history") {
      setPageNo(pageNumber);
      getHistory(pageNumber);
    } else if (tabType == "bookmark") {
      setPage(pageNumber);
      getBookmarklist(pageNumber);
    }
  }

  // get bookmark list

  function getBookmarklist() {
    openAiService
      .getBookmark(page)
      .then((res) => {
        setBookmarks(res?.data?.data);
        setTotalRecords(res.data?.pages * 10);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }

  // delete history
  const deleteHistory = (openaiId) => {
    if (openaiId) {
      openAiService
        .delete_History(openaiId)
        .then((res) => {
          Swal.fire("Success", "History deleted successfully", "success").then(
            () => {
              getHistory();
              window.location.reload();
            }
          );
          getHistory();
        })
        .catch((error) => {});
    }
  };

  // keeps the chat box scrolled down to bottom
  useEffect(() => {
    if (keepScrollingToBottom) {
      element?.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [element?.scrollHeight, elementHeight, trigerScroll]);

  function init() {
    bookmarkDisabled = localStorage.getItem("plan") === null;
    getSubscription();
    listPlans();
  }

  function getHistory() {
    openAiService
      .getChatHistory(pageNo)
      .then((res) => {
        setTotalHistoryRecords(res.data?.pages * 10);
        getBookmarklist();
        setChatCount(res.data.history);
        let historyArray = res.data?.data;
        let bookmarkArray = res.data?.data.filter(
          (obj) => obj.is_bookmarked === true
        );
        if (historyArray.length > 0) {
          setHistory([...historyArray]);
          // setBookmarks([...bookmarkArray])
        } else {
          setPageNo(pageNo > 0 ? pageNo - 1 : 0);
        }
        setHistoryLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }
  // to get the chats of history(or bookmark) when any history is clicked
  function getChat(openaiId, historyData) {
    setShowLoader(false);
    setshowChatResponse(false);
    setOpenaiId(openaiId);
    setActiveChat(historyData);
    openAiService
      .getBookmarkHistory(openaiId)
      .then((res) => {
        let history = res.data?.data;
        setChat([]);
        setChat((prevObj) => [
          ...prevObj,
          { type: "question", text: history.prompt, uploaded_file: history.uploaded_file},
        ]);
        setChat((prevObj) => [
          ...prevObj,
          {
            type: "answer",
            text: history.response,
            pdf_file: history.pdf_file,
          },
        ]);
        setIsBookmarked(history.is_bookmarked ? 1 : 0);
        let children = res.data.children;
        children?.map((historyObj) => {
          const uploadedFileName = historyObj.uploaded_file
            ? historyObj.uploaded_file.split('/').pop()
            : null;

          setChat((prevObj) => [
            ...prevObj,
            { type: "question", text: historyObj.prompt, uploaded_file: uploadedFileName },
          ]);
          setChat((prevObj) => [
            ...prevObj,
            {
              type: "answer",
              text: historyObj.response,
              pdf_file: historyObj.pdf_file,
            },
          ]);
          return null;
        });
        setTriggerScroll(!trigerScroll);
        setKeepScrollingToBottom(true);
        connectWithWebsocket(historyData);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }

  // typing effect function call
  var i = 0;
  var speed = 30;
  function typeWriterEffect() {
    if (i < chatResponse.length) {
      let elm = document.getElementById("chat-response-id");
      if (elm) {
        elm.innerHTML += chatResponse?.charAt(i);
      }
      if (
        element.scrollHeight - (element.scrollTop + element.clientHeight) >
        200
      ) {
        setKeepScrollingToBottom(false);
      } else {
        setKeepScrollingToBottom(true);
      }
      setElementHeight(element.scrollHeight);
      i++;
      setTriggerScroll(!trigerScroll);
      setTimeout(typeWriterEffect, speed);
    } else {
      setshowChatResponse(false);
      setChatTriggered(false);
      setChat((prevObj) => [
        ...prevObj,
        { type: "answer", text: chatResponse },
      ]);
      setChatResponse("");
    }
  }

  const handleSendMessage = useCallback((data) => sendMessage(data), []);
  const askQuestion = (query) => {
    setQueryString("");
    setChat((prevObj) => [
      ...prevObj,
      { type: "question", text: query, question: query ? query : "" },
    ]);
    setTriggerScroll(!trigerScroll);
    setShowLoader(true);
    setChatTriggered(true);
    let data = {
      openai_id: openaiId,
      prompt: query,
    };
    setshowChatResponse(true);
    handleSendMessage(JSON.stringify(data));
  };

  function restartWritting() {
    setStopChatWritting(true);
    askQuestion(queryString);
  }

  function getSubscription() {
    let subId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))?.subscription_plan
      : "";
    openAiService
      .getSubscription(subId)
      .then((res) => {
        if (res.data?.plan.active) {
          if (res.data?.plan.id === DEFAULT_PLAN_ID) {
          } else {
            globalDataRef.current.setCurrentPlan(res.data.plan);
            localStorage.setItem("plan", JSON.stringify(res.data.plan));
          }
        } else {
          localStorage.removeItem("plan");
        }
      })
      .catch((err) => {});
  }

  const listPlans = () => {
    openAiService
      .listSubscriptions()
      .then((res) => {
        let sortedArray = res.data.data.sort(function (a, b) {
          return parseFloat(a.amount) - parseFloat(b.amount);
        });
        globalDataRef.current.setAllSubPlans(sortedArray);
      })
      .catch((error) => {});
  };

  function comparePdf(uploadedFile, uploadedFile1) {
    setQueryString("");
    setShowPdfPrompt(false);
    setCompareShowPdfPrompt(false);
    if (uploadedFile1) {
      let query = `Compare Agreement - ${pdfFile0.name} and ${pdfFile1.name}`;
      setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
    } else {
      let query = "";
      pdfFile.map((filePdf, i) => {
        if (query != "") {
          query = query + " & " + ` ${filePdf.name}`;
        } else {
          query = `${filePdf.name}`;
        }
        if (parseInt(i + 1) == pdfFile.length) {
          query = "Summarize -" + query;
          setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
        }
      });
    }
    setShowLoader(true);
    setChatTriggered(true);
    let data = new FormData();
    for (let i = 0; i < uploadedFile.length; i++) {
      data.append(`pdfFile`, uploadedFile[i]);
    }
    // data.append("pdfFile",uploadedFile)
    if (true) {
      data.append("openai_id", openaiId);
    }
    if (uploadedFile1) {
      data.append("pdfFile1", uploadedFile);
      data.append("pdfFile2", uploadedFile1);
    }
    openAiService
      .comparePdf(data)
      .then((res) => {
        let chatresponse = res.data?.data;
        setOpenaiId(res.data.openai_id);
        setChatResponse(chatresponse);
        let documentElm1 = document.getElementById("upload-pdf-1");
        if (documentElm1) {
          documentElm1.value = "";
        }
        let documentElm2 = document.getElementById("upload-pdf-2");
        if (documentElm2) {
          documentElm2.value = "";
        }
        let documentElm3 = document.getElementById("upload-pdf-3");
        if (documentElm3) {
          documentElm3.value = "";
        }
        let documentElm4 = document.getElementById("upload-pdf-4");
        if (documentElm4) {
          documentElm4.value = "";
        }
        setshowChatResponse(true);
        getHistory();
        setShowLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
      })
      .catch((error) => {
        setShowLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);

        if (globalDataRef.current.currentPlan?.id !== PREMIUM_PLAN_ID) {
          if (error.response.data.error == "Please upgrade your plan.") {
            setShowLoader(false);
            setChatTriggered(false);
            setshowChatResponse(false);
            globalDataRef.current.setPlanValidityExpired(true);
            localStorage.setItem("planExpired", true);
            Swal.fire("Error", "Please upgrade your Plan.", "error");
          }
        }

        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        } else {
          setShowLoader(false);
          setChatTriggered(false);
          setshowChatResponse(false);
          setChat((prevObj) => [
            ...prevObj,
            { type: "answer", text: "Unable to generate response." },
          ]);
        }
      });
  }

  function uploadPdfFiles(uploadedFile, uploadedFile1 = null) {
    setQueryString("");
    setShowPdfPrompt(false);
    setCompareShowPdfPrompt(false);

    let query = "";

    if (uploadedFile1) {
      query = `Compare Both Agreements: - ${pdfFile0.name} and ${pdfFile1.name}`;
      // setChat(prevObj => [...prevObj, { "type": "question", "text": query }])
    } else {
      pdfFile.map((filePdf, i) => {
        if (query != "") {
          query = query + " & " + ` ${filePdf.name}`;
        } else {
          query = `${filePdf.name}`;
        }
        if (parseInt(i + 1) == pdfFile.length) {
          query = "Summarize: - " + query;
          // setChat(prevObj=>[...prevObj, {"type": "question", "text": query}])
        }
      });
    }
    setMainLoader(true);
    setChatTriggered(true);
    let data = new FormData();
    for (let i = 0; i < uploadedFile.length; i++) {
      data.append(`pdfFile`, uploadedFile[i]);
    }

    if (uploadedFile1) {
      data.append("pdfFile1", uploadedFile);
      data.append("pdfFile2", uploadedFile1);
    }

    openAiService
      .uploadPdfFiles(data)
      .then((res) => {
        setOpenaiId(res.data.data.assistant_id);

        setSocketUrl(`${websocket}/pdf/${res.data.data.assistant_id}`);

        let documentElm1 = document.getElementById("upload-pdf-1");
        if (documentElm1) {
          documentElm1.value = "";
        }
        let documentElm2 = document.getElementById("upload-pdf-2");
        if (documentElm2) {
          documentElm2.value = "";
        }
        let documentElm3 = document.getElementById("upload-pdf-3");
        if (documentElm3) {
          documentElm3.value = "";
        }
        let documentElm4 = document.getElementById("upload-pdf-4");
        if (documentElm4) {
          documentElm4.value = "";
        }
        setshowChatResponse(true);
        // getHistory();
        setMainLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);

        setTimeout(() => {
          askQuestion(query);
        }, 500);
      })
      .catch((error) => {
        setMainLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);

        if (globalDataRef.current.currentPlan?.id !== PREMIUM_PLAN_ID) {
          if (error.response.data.error == "Please upgrade your plan.") {
            setMainLoader(false);
            setChatTriggered(false);
            setshowChatResponse(false);
            globalDataRef.current.setPlanValidityExpired(true);
            localStorage.setItem("planExpired", true);
            Swal.fire("Error", "Please upgrade your Plan.", "error");
          }
        }

        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        } else {
          setMainLoader(false);
          setChatTriggered(false);
          setshowChatResponse(false);
          Swal.fire(
            "Error",
            "Server having problem while processing the request right now, please try again later!",
            "error"
          );
        }
      });
  }

  function deleteUserData(index) {
    let temp = [...pdfFile];
    temp.splice(index, 1);
    setPdfFile(temp);
  }

  const upgradeToPremium = () => {
    openAiService.getPlan(PREMIUM_PLAN_ID).then((res) => {
      let plan = res.data;
      navigate("/card_details", { state: { plan: plan } });
    });
  };

  const checkFile = (allfile) => {
    for (const key of Object.keys(allfile.files)) {
      let file = allfile.files[key];
      if (file.type === "application/pdf") {
        return { status: true, message: "" };
      } else {
        return { status: false, message: "Upload a valid PDF file." };
      }
    }
  };

  const handleQuerySubmit = (e) => {
    try {
      e.preventDefault();
    } catch (error) {
      console.error("Error preventing default action:", error);
    }
  
    if (showPdfPrompt) {
      if (pdfFile) {
        startNewChat();
        // comparePdf(pdfFile);
        uploadPdfFiles(pdfFile);
      } else {
        Swal.fire("Warning", "Please select a PDF file!", "warning");
      }
    } else if (showComparePdfPrompt) {
      if (pdfFile0 && pdfFile1) {
        startNewChat();
        // comparePdf(pdfFile0, pdfFile1);
        uploadPdfFiles(pdfFile0, pdfFile1);
      } else if (!pdfFile0 || !pdfFile1) {
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
        Swal.fire(
          "Warning",
          "Please select both PDF files to compare",
          "warning"
        );
      }
    } else if (activeChat?.prompt_type =="create_agreement" && uploadedPdf) {
      if (!queryString.trim()) {
        Swal.fire("Warning", "Enter some query to ask!", "warning");
      } else {
        analyse_pdf();
      }
    } else {
      if (queryString.trim() === "") {
        Swal.fire("Warning", "Enter some query to ask!", "warning");
      } else {
        askQuestion(queryString);
      }
    }
  };
  const createAgreement = (e) => {
    setShowLoader(true);
    setChatTriggered(true);
    openAiService
      .createContract(contractField)
      .then((res) => {
        let chatresponse = res.data?.data;
        setOpenaiId(res.data.openai_id);
        setChatResponse(chatresponse);
        setQueryString("");
        let documentElm1 = document.getElementById("upload-pdf-1");
        if (documentElm1) {
          documentElm1.value = "";
        }
        let documentElm2 = document.getElementById("upload-pdf-2");
        if (documentElm2) {
          documentElm2.value = "";
        }
        let documentElm3 = document.getElementById("upload-pdf-3");
        if (documentElm3) {
          documentElm3.value = "";
        }
        let documentElm4 = document.getElementById("upload-pdf-4");
        if (documentElm4) {
          documentElm4.value = "";
        }
        setshowChatResponse(true);
        getHistory();
        setShowLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
      })
      .catch((error) => {});
  };

  const generateAgreement = () => {
    const query = `Agreement ${contractField?.identifyPartie}`;
    setChat((prevObj) => [
      ...prevObj,
      { type: "question", text: query, question: query ? query : "" },
    ]);
    setShowLoader(true);
    setChatTriggered(true);
    setshowChatResponse(true);
    openAiService
      .generateContract(contractField)
      .then((res) => {
        let documentElm1 = document.getElementById("upload-pdf-1");
        if (documentElm1) {
          documentElm1.value = "";
        }
        let documentElm2 = document.getElementById("upload-pdf-2");
        if (documentElm2) {
          documentElm2.value = "";
        }
        let documentElm3 = document.getElementById("upload-pdf-3");
        if (documentElm3) {
          documentElm3.value = "";
        }
        let documentElm4 = document.getElementById("upload-pdf-4");
        if (documentElm4) {
          documentElm4.value = "";
        }
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
        setActiveChat(res?.data?.data);
      })
      .catch((error) => {});
  };

  const downloadContractPdf = (text, index) => {
    setMainLoader(true);
    openAiService
      .generateContractPdf({
        content: text,
        index: Math.floor(index / 2),
        openai_id: openaiId,
      })
      .then((res) => {
        const url = baseUrl + "/" + res.data.data;
        // find index and update
        let temp = [...chat];
        temp[index].pdf_file = res.data.data;
        setChat(temp);
        setMainLoader(false);
        window.open(url, "_blank");
      })
      .catch((error) => {
        setMainLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);

        if (globalDataRef.current.currentPlan?.id !== PREMIUM_PLAN_ID) {
          if (error.response.data.error == "Please upgrade your plan.") {
            setMainLoader(false);
            setChatTriggered(false);
            setshowChatResponse(false);
            globalDataRef.current.setPlanValidityExpired(true);
            localStorage.setItem("planExpired", true);
            Swal.fire("Error", "Please upgrade your Plan.", "error");
          }
        }

        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        } else {
          setMainLoader(false);
          setChatTriggered(false);
          setshowChatResponse(false);
          Swal.fire(
            "Error",
            "Server having problem while processing the request right now, please try again later!",
            "error"
          );
        }
      });
  };

  const startNewChat = () => {
    setOpenaiId();
    setChat([]);
    setActiveChat({});
    // setChat(prevObj=>[...prevObj, {"type": "answer", "text": ""}])
    setIsBookmarked(0);
  };

  // to bookmark/unbookmark any chat history
  const bookmark = () => {
    if (openaiId) {
      openAiService
        .bookmarkHistory(openaiId)
        .then((res) => {
          setIsBookmarked(isBookmarked == 0 ? 1 : 0);
          getHistory();
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.clear();
            globalDataRef.current.setSignUpModal(true);
            navigate("/");
          } else {
            Swal.fire("Error", error?.response?.data?.error, "error");
          }
        });
    }
  };


  const handleStopButtonClick = () => {
    setSocketUrl(null)
    setshowChatResponse(false);
    setChatTriggered(false);
    let answer = generatedAnswer.current;
      setChat((prevObj) => [...prevObj, { type: "answer", text: answer }]);
      generatedAnswer.current = "";
      let elm = document.getElementById("chat-response-id");
      if (elm) {
        elm.innerHTML = "";
      }
      if (localStorage.getItem("plan")) {
        getHistory();
      }
    console.log('WebSocket disconnected gracefully');
    setTimeout(() => {
      connectWithWebsocket();
    }, 500);
  };

  // const handleStopButtonClick = () => {
  //   const webSocketInstance = disconnect;
  //   if (webSocketInstance) {
  //     setStopChatWritting(false);
  //     webSocketInstance.close();
  //     console.log('WebSocket closed');
  //   }
  //   connectWithWebsocket()
  // };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
  
    if (event.target.files.length > 1) {
      event.target.value = null;
      Swal.fire("", "Please select only one file.", "warning");
      return;
    }
  
    if (file.type !== "application/pdf") {
      event.target.value = null;
      Swal.fire("", "Only PDF file is allowed.", "warning");
      return;
    }
  
    setUploadedPdf(file);
  };
  
  const analyse_pdf = () => {
    const formData = new FormData();
    formData.append("file", uploadedPdf);
    formData.append("content", queryString);
    formData.append("openai_id", openaiId);
  
    setShowLoader(true);
    setChatTriggered(true);
    setshowChatResponse(true);
    setUploadedPdf(null);
    setQueryString("");
  
    setChat((prevObj) => [
      ...prevObj,
      { 
        type: "question", 
        text: queryString, 
        question: queryString || "", 
        uploaded_file: uploadedPdf?.name 
      },
    ]); 
    console.log("uploaded_file_name",uploadedPdf?.name)
    openAiService
      .analysePdf(formData)
      .then((res) => {
        setPdfFile(); 
        // setUploadedPdf(null);
        // setQueryString("");
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
        // setActiveChat(res?.data?.data); 
      })
      .catch((error) => {
        console.error("Error analyzing PDF:", error);
      })
      .finally(() => {
        setShowLoader(false); 
      });
  };

  return (
    <>
      {mainLoader && (
        <div className="main-loader">
          <div className="loader3">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
      <section className="inner-chatbot" id="inner-chatbot-id">
        <Container>
          <Row>
            <Col lg={3} md={5}>
              <div className="left-chat-boxes">
                <div className="overflow">
                  <div className="chat-history-header">
                    <span>{`Hello${
                      localStorage.getItem("user")
                        ? `, ${capitalizeFirstLetter(
                            JSON.parse(localStorage.getItem("user")).firstname
                          )}`
                        : ""
                    } 👋`}</span>
                  </div>
                  <div className="add-chat-btn">
                    <Button
                      type="button"
                      variant="unset"
                      //  disabled={showLoader || showChatResponse || chatTriggered }
                      onClick={startNewChat}
                    >
                      + New chat
                    </Button>
                  </div>
                  <h5 className="text-center">Bookmarks:</h5>
                  <div className="chat-bookmark-area chat-history-left">
                    {bookmarks.length ? (
                      bookmarks.map((historyObj, index) => {
                        return (
                          <>
                            <span
                              key={historyObj.openai_id}
                              className={
                                historyObj.openai_id == openaiId
                                  ? "history-obj-active"
                                  : ""
                              }
                              onClick={() => {
                                getChat(historyObj.openai_id, historyObj);
                              }}
                            >
                              {`${historyObj.prompt
                                .substring(0, 27)
                                .trim()}...`}
                              {/* {historyObj.pdf_file ?
                                <a href={baseUrl + "/" + historyObj.pdf_file} target="_blank" title="Download Agreement">
                                  <i className="fa fa-download" aria-hidden="true" ></i></a> : null} */}
                            </span>
                          </>
                        );
                      })
                    ) : !historyLoading ? (
                      <p>
                        {localStorage.getItem("plan") === null
                          ? `Upgrade plan to save Bookmarks`
                          : `No bookmarks yet :(`}{" "}
                      </p>
                    ) : (
                      <RotatingLines width="20" strokeColor="#111" />
                    )}
                    {totalRecord > 15 ? (
                      <div className="pagination1">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={12}
                          totalItemsCount={totalRecord ? totalRecord : 0}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e, "bookmark")}
                          hideDisabled={true}
                          prevPageText={"<"}
                          nextPageText={">"}
                        />
                      </div>
                    ) : null}
                  </div>

                  <h5 className="text-center mt-2">History:</h5>
                  <div className="chat-history-area chat-history-left">
                    {/* {history?.length > 0}  */}
                    {history.length > 0 ? (
                      <button
                        className="clear-all-btn"
                        type="button"
                        onClick={(e) => {
                          Swal.fire({
                            icon: "info",
                            html: `<div className="logout-text">
                                  Are you sure you want to delete all history?
                                  </div>
                                  `,
                            showCloseButton: true,
                            showConfirmButton: true,
                            showCancelButton: true,
                          }).then((res) => {
                            if (res.isConfirmed) {
                              let openai_id = "all";
                              // DeleteDraft(item?.id)
                              deleteHistory(openai_id);
                            }
                          });
                        }}
                      >
                        <img src={require("../../assests/images/clear.png")} />
                        Clear All
                      </button>
                    ) : null}

                    {history.length && !historyLoading ? (
                      history.map((historyObj, index) => {
                        return (
                          <>
                            <span
                              key={historyObj.openai_id}
                              className={
                                historyObj.openai_id == openaiId
                                  ? "history-obj-active"
                                  : ""
                              }
                              onClick={() => {
                                getChat(historyObj.openai_id, historyObj);
                              }}
                            >
                              {`${historyObj.prompt
                                .substring(0, 27)
                                .trim()}...`}
                              {/* {historyObj.pdf_file && (downloadIcon != false) ?

                                <a href={baseUrl + "/" + historyObj.pdf_file} target="_blank" title="Download Agreement">
                                  <i className="fa fa-download" aria-hidden="true" ></i></a> :
                                historyObj.pdf_file && (!downloadIcon && index != 0) ?

                                  <a href={baseUrl + "/" + historyObj.pdf_file} target="_blank" title="Download Agreement">
                                    <i className="fa fa-download" aria-hidden="true" ></i></a> : null} */}
                              {historyObj && (
                                <>
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                    onClick={(e) => {
                                      Swal.fire({
                                        icon: "info",
                                        html: `<div className="logout-text">
                                  Are you sure you want to delete this history?
                                  </div>
                                  `,
                                        showCloseButton: true,
                                        showConfirmButton: true,
                                        showCancelButton: true,
                                      }).then((res) => {
                                        if (res.isConfirmed) {
                                          // DeleteDraft(item?.id)
                                          deleteHistory(historyObj?.openai_id);
                                        }
                                      });
                                    }}
                                  ></i>
                                </>
                              )}
                            </span>
                          </>
                        );
                      })
                    ) : !historyLoading ? (
                      <>
                        <p>
                          {localStorage.getItem("plan") === null
                            ? `Upgrade plan to save History`
                            : `No history yet :(`}{" "}
                        </p>
                        {localStorage.getItem("plan") === null && (
                          <div
                            className="upgrade-to-plan-style"
                            onClick={() => {
                              globalDataRef.current.setScrollToPricing(true);
                              navigate("/");
                            }}
                          >
                            Upgrade Plan
                          </div>
                        )}
                      </>
                    ) : (
                      <RotatingLines width="20" strokeColor="#111" />
                    )}
                  </div>
                  {totalHistoryRecords > 15 ? (
                    <div className="pagination1">
                      <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={12}
                        totalItemsCount={
                          totalHistoryRecords ? totalHistoryRecords : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e, "history")}
                        hideDisabled={true}
                        prevPageText={"<"}
                        nextPageText={">"}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={9} md={7}>
              <div className="mid-chat-box">
                <div className="chatbox-header">
                  <h3>AI attorney ✍️</h3>
                  <span>
                    {
                      // JSON.parse(localStorage.getItem("plan")) && JSON.parse(localStorage.getItem("plan")) != "null" &&
                      //   openaiId && bookmarkDisabled && isBookmarked == 1 ?
                      //   <img onClick={bookmark} className="bookmark-img" src={require("../../assests/images/bookmark.png")} alt="bookmark"></img>
                      //   :
                      //   openaiId && isBookmarked === 0 && bookmarkDisabled
                      //     ?
                      //     <img onClick={bookmark} className="bookmark-img" src={require("../../assests/images/bookmark-white.png")} alt="bookmark"></img>
                      //     : (!bookmarkDisabled && chatHistoryObj?.is_bookmarked && chatHistoryObj?.openai_id) ?
                      //       <img onClick={bookmark} className="bookmark-img" src={require("../../assests/images/bookmark.png")} alt="bookmark"></img>
                      //       : null
                      JSON.parse(localStorage.getItem("user"))
                        ?.subscription_plan != "null" &&
                      openaiId &&
                      bookmarkDisabled &&
                      isBookmarked == 1 ? (
                        <img
                          onClick={bookmark}
                          className="bookmark-img"
                          src={require("../../assests/images/bookmark.png")}
                          alt="bookmark"
                        ></img>
                      ) : // null
                      openaiId && isBookmarked === 0 && bookmarkDisabled ? (
                        <img
                          onClick={bookmark}
                          className="bookmark-img"
                          src={require("../../assests/images/bookmark-white.png")}
                          alt="bookmark"
                        ></img>
                      ) : !bookmarkDisabled &&
                        chatHistoryObj?.is_bookmarked &&
                        chatHistoryObj?.openai_id ? (
                        <img
                          onClick={bookmark}
                          className="bookmark-img"
                          src={require("../../assests/images/bookmark.png")}
                          alt="bookmark"
                        ></img>
                      ) : null
                    }
                  </span>
                </div>
                <div id="chatbox-body-id" className="chatbox-body">
                  {openaiId ? null : (
                    <>
                      <div className="left-chat-box top-spacing">
                        <p>
                          Please choose a question below, or feel free to type
                          your own inquiry
                        </p>
                      </div>
                      {sampleQuestions.map((que, index) => {
                        return (
                          <div
                            key={index}
                            className="left-chat-box top-spacing"
                          >
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setQueryString(que[1]);
                                setLastQuestion(que[1]);
                              }}
                            >
                              {que.join(" ")}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}
                  {chat.length > 0
                    ? chat.map((chatObj, index) => {
                        if (chatObj.type === "answer") {
                          return (
                            <div key={index} className="left-chat-box">
                              <p>
                                {chatObj.text}{" "}
                                {activeChat?.prompt_type ==
                                  "create_agreement" && (
                                  <div className="agrrement-regenrate under-agrement-text">
                                    <div className="agrement-btn">
                                    <a
                                      className="sub-para1"
                                      role="button"
                                      onClick={() =>
                                        downloadContractPdf(chatObj.text, index)
                                      }
                                    >
                                      <b>
                                        {chatObj?.pdf_file
                                          ? "Re-generate"
                                          : "Generate"}{" "}
                                        Agreement PDF
                                      </b>
                                    </a>
                                    {chatObj?.pdf_file && (
                                      <a
                                        href={baseUrl + "/" + chatObj.pdf_file}
                                        target="_blank"
                                        title="Download Agreement"
                                      >
                                        <i
                                          className="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                    </div>
                                  </div>
                                )}
                              </p>
                              <br></br>
                              {localStorage.getItem("plan") &&
                              JSON?.parse(localStorage.getItem("plan"))?.id ? (
                                <p className="sub-para1">
                                  For Personalized consultation{" "}
                                  <a className="click-me-btn"
                                    onClick={() =>
                                      globalDataRef.current.setShowContactUsModal(
                                        true
                                      )
                                    }
                                  >
                                    click here
                                  </a>
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div key={index} className="right-chat-box under-file-upload">
                              {chatObj?.uploaded_file && (
                                <p className="file-upload-text">{chatObj.uploaded_file}</p>
                              )}
                              <p className="main-file-upload-text">{chatObj.text}</p>
                            </div>
                          );
                        }
                      })
                    : null}

                  {showLoader && (
                    <div className="left-chat-box">
                      <div className="typing-loader"></div>
                    </div>
                  )}
                  {showChatResponse && (
                    <div className="left-chat-box">
                      <p id="chat-response-id"></p>
                    </div>
                  )}
                  <div className="upload-pdf-outer">
                    {uploadedPdf && (
                      <div className="uploaded-pdf-area pdf-upload-text">
                        <p className="pdf-upload">{uploadedPdf?.name}</p>
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          onClick={() => setUploadedPdf(null)}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
                <div className="chatbox-footer">
                  {/* {showChatResponse && (
                    <div className="stop-generating">
                      {chat.length > 0 && stopChatWritting && (
                        <button type="button" onClick={handleStopButtonClick}>
                          <i className="fa fa-square-o"></i> Stop Generating
                        </button>
                      )}
                    </div>
                  )} */}
                  {/* {!stopChatWritting &&
                  <div className="stop-generating" >
                    {
                      chat.length > 0 && 
                        
                        <button onClick={() =>{
                          if(showPdfPrompt){
                            comparePdf(restartPdf)
                          }
                          else{
                            setStopChatWritting(true)
                            askQuestion(lastQuestion)}}
                          }><i className="fa fa-refresh" aria-hidden="true"></i>Regenerate response</button>

                    }
                  </div>} */}
                  
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    className="prompt"
                  >
                    Prompt Library +
                  </Button>
                  <form
                    onSubmit={(e) => {
                      handleQuerySubmit(e);
                    }}
                  >
                    {activeChat?.prompt_type =="create_agreement" && <div className="attachment-area under-attachment-area">
                     
                      <input type="file" id="myfile" name="myfile" onChange={(e) => handlePdfUpload(e)} />
                      <img
                        src={require("../../assests/images/file.svg").default}
                      />
                    </div>}
                    {/* <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        disabled={
                          showLoader ||
                          showChatResponse ||
                          chatTriggered ||
                          isListening
                        }
                      >
                        <img
                          src={require("../../assests/images/file.svg").default}
                        />
                      </Dropdown.Toggle>
                          <Form.Control className="upload-pdf-file" onChange={(e) => handlePdfUpload(e)} type="file" accept="application/pdf" size="lg" />
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="attachment-upload">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                            Upload
                            <input type="file" ></input>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <textarea
                      // id="input_focused"
                      className="noSelect"
                      disabled={
                        showLoader ||
                        showComparePdfPrompt ||
                        showPdfPrompt ||
                        showChatResponse ||
                        chatTriggered ||
                        isListening
                      }
                      type="text"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleQuerySubmit(e);
                        }
                      }}
                      placeholder="Ask something!"
                      rows={2}
                      value={queryString}
                      id={"textInputId"}
                      style={{ overflowY: "auto" }}
                      ref={inputRef}
                      onChange={(e) => {
                        setQueryString(e.target.value);
                        setLastQuestion(e.target.value);
                      }}
                    ></textarea>
                    <div className="send-record-btn">
                    {showChatResponse ? (
                        <button
                        type="button"
                        onClick={handleStopButtonClick}
                        disabled={!showChatResponse}
                        className={"submit submit-light"}
                      >
                        <i className="fa fa-stop" aria-hidden="true"></i>
                      </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={
                            showLoader ||
                            showChatResponse ||
                            chatTriggered ||
                            isListening ||
                            queryString.trim().length === 0
                          }
                          className={
                            showLoader ||
                            showChatResponse ||
                            chatTriggered ||
                            isListening ||
                            queryString.trim().length === 0
                              ? "send-btn-disabled submit submit-light"
                              : "submit submit-light"
                          }
                        >
                          <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      )}
                      <div className="microphone-section-area">
                        <>
                          <div
                            className="microphone-icon-container submit"
                            ref={microphoneRef}
                            disabled={
                              showLoader || showChatResponse || chatTriggered
                            }
                            onClick={(e) => {
                              if (isListening) {
                                stopHandle();
                                return;
                              }
                              if (
                                ((localStorage.getItem("plan") &&
                                  JSON?.parse(localStorage.getItem("plan"))
                                    ?.id) ||
                                  globalDataRef.current.currentPlan?.id) ===
                                PREMIUM_PLAN_ID
                              ) {
                                handleListing();
                              } else {
                                setUpgradePlanPopupModal(true);
                              }
                            }}
                          >
                            {!isListening ? (
                              <i
                                className={"fa fa-microphone microphone-icon"}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <span className="stop-text">
                                <i class="fa fa-stop" aria-hidden="true"></i>
                              </span>
                            )}
                          </div>
                          <div className="microphone-status">
                            <i className="fa-light fa-waveform-lines"></i>
                          </div>
                        </>
                      </div>
                    </div>
                  </form>
                  {isListening == true && (
                    <h6 className="sub-para">
                      {" "}
                      Duration:{" "}
                      {Math.floor(duration / 60) +
                        " minute " +
                        Math.floor(duration % 60) +
                        " seconds"}
                    </h6>
                  )}
                  <p className="sub-para">
                    AIattorney may produce inaccurate information about people,
                    places, or facts.
                  </p>
                </div>
                {/* } */}
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </section>
      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Prompt Library</h5>
            <p className="paragraph-txt">
              Choose the prompt that suits you best. Once you click "+", it'll
              appear in the text input field. You can then send it as is or add
              your own words.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="prompt-box right-chat-box-dropdown prompt-library-dropdown-area">
            <Dropdown className="my-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Prompts for Legal Consumers
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">
                  <p
                    onClick={(e) => {
                      let que = e.target.innerHTML;
                      setQueryString(que);
                      setShow(false);
                    }}
                  >
                    What are my rights as an employee?{" "}
                  </p>
                  <p
                    onClick={(e) => {
                      let que = e.target.innerHTML;
                      setQueryString(que);
                      setShow(false);
                    }}
                  >
                    How do I file for divorce and what are the requirements?
                  </p>
                  <p
                    onClick={(e) => {
                      let que = e.target.innerHTML;
                      setQueryString(que);
                      setShow(false);
                    }}
                  >
                    How do I create a will or trust?
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="my-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Prompts for Legal Research
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="legal-research-area">
                  <Dropdown.Item href="#">
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Provide examples of [legal case/issue]{" "}
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the latest developments in [legal area]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the relevant laws or regulations regarding [legal
                      issue]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the history of [legal case/issue]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the legal definition of [legal term or phrase]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the legal precedent for [legal case/issue]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the pros and cons of [legal argument/position]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the standard for [legal issue] in [jurisdiction]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the key legal arguments in [legal case/issue]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Provide a summary of [case name]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Summarize the following contract: [copy and paste
                      contract]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the statute of limitations for [type of case] in
                      [state or jurisdiction]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Outline the steps involved in [legal process or procedure]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the significance of [case name]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Describe the elements of [legal claim or cause of action]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the main defenses to [legal claim or cause of
                      action]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the burden of proof in [type of case]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Analyze the impact of [legal case/issue] on [specific
                      industry or area of law]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the potential consequences or penalties for
                      [legal violation]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Provide an overview of the [legal issue] in international
                      law
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Compare and contrast [legal concept] in different
                      jurisdictions
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Compare and contrast the legal concept of negligence in
                      the United States and the United Kingdom.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Discuss the role of [legal principle or concept] in
                      [specific area of law]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Identify the key cases and legal authorities on [legal
                      issue]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Explain the concept of [legal term] and its relevance to
                      [specific area of law]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the ethical considerations in [legal issue or
                      situation]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Describe the role of [legal professional or organization]
                      in [legal system or process]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Discuss the implications of recent legal reforms on
                      [specific area of law]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the upcoming changes in [legal area]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Analyze the potential impact of [legal issue] on [specific
                      group or community]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      How have recent court decisions affected the
                      interpretation of [legal principle or concept]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Discuss the relationship between [legal issue] and [public
                      policy or social issue]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the potential implications of [legal case/issue]
                      on future cases or legislation?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the key factors that courts consider when
                      interpreting [legal provision or statute]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      How has [legal case/issue] affected [specific industry or
                      sector]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the role of [government agency] in enforcing
                      [legal regulation or requirement]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Explain the differences between [type of law] and [another
                      type of law]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Discuss the evolution of [legal principle or concept] over
                      time
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Describe the various forms of [legal entity or structure].
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the primary sources of law for [legal issue or
                      area of law]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Explain the role of [legal document] in [legal process or
                      transaction]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Analyze the relationship between [legal issue] and
                      [constitutional provision or principle]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What are the potential remedies for [legal harm or
                      violation]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Provide examples of [legal case/issue]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      What is the legal basis for [government action or
                      regulation]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Discuss the challenges and limitations of [legal approach
                      or solution] to [problem or issue]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      How does [legal case/issue] relate to [similar or related
                      legal case/issue]?
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Provide examples of landmark cases in [specific area of
                      law]
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      How does [legal concept] apply in [real-life situation or
                      hypothetical scenario]?
                    </p>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="my-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Prompts for Paralegal Lawyers
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="legal-research-area">
                  <Dropdown.Item href="#">
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in conducting research on a specific legal
                      issue or case law related to a particular area of law.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      help in drafting or reviewing legal documents such as
                      contracts, pleadings, or agreements.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Support in gathering information and conducting background
                      checks on parties involved in a legal case.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in reviewing and analyzing legal documents,
                      contracts, or agreements for accuracy, compliance, and
                      potential risks.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Support in preparing and filing court documents, including
                      motions, briefs, and other necessary filings.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in preparing interview questions and gathering
                      relevant information in preparation for client interviews.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Organizing and managing discovery materials, including
                      reviewing and summarizing documents, preparing
                      interrogatories, and drafting responses.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Preparation of a detailed memorandum outlining legal
                      research findings, analysis, and recommendations for a
                      specific legal issue.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in trial preparation, including organizing
                      trial exhibits, drafting witness outlines, and preparing
                      trial binders.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Write Draft formatting legal documents according to
                      specific court or jurisdictional requirements.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Translation services for legal documents, contracts, or
                      agreements from one language to another.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in verifying and checking legal citations in
                      briefs, motions, or other legal documents for accuracy and
                      relevance.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Support in indexing and organizing legal documents for
                      easy retrieval and reference.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in notarizing legal documents to ensure their
                      validity and authenticity.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in obtaining authentication or apostille
                      services for legal documents to ensure their acceptance in
                      foreign jurisdictions.
                    </p>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="my-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Prompts for Immigration Lawyers
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="legal-research-area">
                  <Dropdown.Item href="#">
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Process of adjusting immigration status, including
                      eligibility requirements, necessary documentation, and
                      potential challenges.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Requesting assistance in navigating the complexities of
                      family-based immigration.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Guidance on employment-based immigration options,
                      including visa categories, eligibility criteria, and the
                      process of obtaining work authorization.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in preparing and submitting visa applications,
                      such as H-1B, L-1, O-1, or E-2 visas.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Guidance in deportation proceedings, including strategies
                      for defense, eligibility for relief
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in preparing asylum or refugee applications,
                      including gathering evidence, crafting a persuasive
                      narrative.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Guidance on the process of becoming a U.S. citizen,
                      including eligibility requirements, document preparation.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in preparing waivers and hardship applications,
                      such as I-601A provisional waivers or I-601 waivers for
                      grounds of inadmissibility.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Process of consular processing for immigrant visa
                      applications, including document preparation, medical
                      examinations.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Information and guidance on Deferred Action for Childhood
                      Arrivals (DACA) applications, renewal procedures.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Guidance on employer compliance with immigration
                      regulations, including I-9 verification, audits, and
                      compliance training.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Assistance in applying for or renewing Temporary Protected
                      Status based on designated countries or humanitarian
                      conditions.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Guidance on U visa applications for victims of crimes or
                      Violence Against Women Act (VAWA) self-petitions for
                      victims of domestic violence.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      {" "}
                      Information and guidance on the impact of travel bans or
                      executive orders on immigration processes, eligibility,
                      and waivers.
                    </p>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="my-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Prompts for Civil Litigation
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="legal-research-area">
                  <Dropdown.Item href="#">
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Prepare a pre-trial motion to compel the defendant to
                      produce relevant financial documents.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Draft a response to the complaint, asserting comparative
                      negligence and third-party claims against subcontractors.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Prepare a detailed outline for the deposition of an expert
                      witness who will testify about the standard of care in the
                      medical field.
                    </p>

                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      A landlord is being sued for alleged violations of the
                      Fair Housing Act by a tenant. Draft an initial written
                      discovery request, seeking information about the tenant's
                      claims and relevant rental policies.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Create a list of potential options for resolution and
                      strategies to facilitate productive communication during
                      mediation.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Draft a summary judgment motion arguing that the
                      plaintiff's claims lack sufficient evidence to proceed to
                      trial.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Develop an outline for the direct examination of character
                      witnesses to bolster your client's reputation.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Create a trial brief outlining the elements of invasion of
                      privacy and providing examples of how they apply to the
                      case.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Prepare a trial exhibit list, including photographs of the
                      accident scene and medical records of the injuries
                      sustained.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Draft a motion in limine to exclude certain pieces of
                      evidence that are irrelevant or prejudicial to the case.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Compile a comprehensive witness list, including expert
                      witnesses who can testify about the product's defects and
                      potential hazards.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Prepare a persuasive brief arguing for the issuance of the
                      injunction based on applicable environmental laws and
                      regulations.
                    </p>
                    <p
                      onClick={(e) => {
                        let que = e.target.innerHTML;
                        setQueryString(que);
                        setShow(false);
                      }}
                    >
                      Draft a motion to dismiss, asserting qualified immunity
                      for the government officials involved.
                    </p>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={upGradePlanPopupModal}
        onHide={() => {
          setUpgradePlanPopupModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Enter your Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ cursor: "pointer" }}>
            <div className="upgrade-plan">
              <img
                src={require("../../assests/images/upgrade.png")}
                alt="img"
              />
              <p className="premium-plans">
                For access to this feature, upgrade to Premium.
              </p>
              <div
                className="upgrade-to-pro-style w-50"
                onClick={() => {
                  globalDataRef.current.setScrollToPricing(true);
                  globalDataRef.current.setShowPopupModal(false);
                  upgradeToPremium();
                }}
              >
                {" "}
                Upgrate to Premium
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={globalDataRef.current.showPopupModal}
        onHide={() => {
          globalDataRef.current.setShowPopupModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Enter your Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes right-chat-spacing">
            <div className="left-chat-box">
              <div className="chat-history-header">
                <span>Other Features 🦾</span>
              </div>
              <div className="right-chat-box-dropdown chat-agreement-area other-feature-dropdown">
                <Dropdown autoClose={true}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Summarize Agreement
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    Upload Documents
                    <div>
                      <Form.Group
                        controlId="formFile"
                        className="uplaod-document-area"
                      >
                        <Form.Label>
                          Click to browse or drag
                          <br /> and drop PDF file
                        </Form.Label>
                        <Form.Control
                          type="file"
                          multiple={true}
                          accept="application/pdf"
                          id="upload-pdf-1"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            let check = checkFile(e.target);
                            if (check.status) {
                              setShowPdfPrompt(true);
                              let fileData = e.target.files;
                              let pdfFileData = pdfFile ? [...pdfFile] : [];
                              if (
                                pdfFileData?.length + e.target.files?.length >
                                5
                              ) {
                                Swal.fire(
                                  "Warning",
                                  "You can upload a maximum of 5 files.",
                                  "warning"
                                );
                              } else {
                                if (
                                  e.target.files &&
                                  e.target?.files?.length > 1
                                ) {
                                  setPdfFile([...pdfFileData, ...fileData]);
                                  setRestartPdf([...pdfFileData, ...fileData]);
                                } else {
                                  setPdfFile([...pdfFileData, fileData[0]]);
                                  setRestartPdf([...pdfFileData, ...fileData]);
                                }
                              }
                            } else {
                              Swal.fire("Warning", check.message, "warning");
                            }
                          }}
                        />
                      </Form.Group>
                      {pdfFile &&
                        pdfFile?.length > 0 &&
                        pdfFile?.map((filePdf) => {
                          return (
                            <p className="sub-para upload-document">
                              {filePdf?.name?.length > 20
                                ? `${filePdf?.name?.substring(20, 0).trim()}...`
                                : filePdf?.name}
                              {}
                              <i
                                className="fa fa-close"
                                onClick={() => deleteUserData(filePdf.index)}
                              ></i>
                            </p>
                          );
                        })}
                      <br />
                      <div>
                        <h6 className="note">
                          Note:-The PDF file(s) should consist of exactly 4000
                          words.{" "}
                        </h6>
                      </div>
                      {}
                      <button
                        className={
                          showChatResponse || !pdfFile ? "btn-disabled" : ""
                        }
                        disabled={showChatResponse || !pdfFile}
                        onClick={() => {
                          if (
                            ((localStorage.getItem("plan") &&
                              JSON?.parse(localStorage.getItem("plan"))?.id) ||
                              globalDataRef.current.currentPlan?.id) ===
                            PREMIUM_PLAN_ID
                          ) {
                            setShowPdfPrompt(true);
                            globalDataRef.current.setShowPopupModal(false);
                            handleQuerySubmit();
                          } else {
                            setUpgradePlanPopupModal(true);
                          }
                        }}
                      >
                        Go
                      </button>
                    </div>
                    {/* </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="right-chat-box-dropdown chat-agreement-area other-feature-dropdown">
                <Dropdown autoClose={true}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic-id">
                    Compare Agreements
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    Upload Documents
                    <div>
                      <Form.Group className="uplaod-document-area">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Click to browse or drag <br /> and drop PDF file
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept="application/pdf"
                          id="upload-pdf-2"
                          style={{ minWidth: "100%" }}
                          multiple={false}
                          onChange={(e) => {
                            let check = checkFile(e.target);
                            if (check.status) {
                              setPdfFile0(e.target.files[0]);
                              setTimeout(() => {
                                setCompareShowPdfPrompt(true);
                              }, 300);
                            } else {
                              Swal.fire("Warning", check.message, "warning");
                            }
                          }}
                        />
                      
                        {pdfFile0 ? (
                          pdfFile0.name.length > 20 ? (
                            <p className="sub-para">{`${pdfFile0.name
                              .substring(20, 0)
                              .trim()}...`}</p>
                          ) : (
                            <p className="sub-para">{pdfFile0.name}</p>
                          )
                        ) : null}
                      </Form.Group>
                     
                      <Form.Group className="uplaod-document-area">
                        <Form.Label>
                          Click to browse or drag
                          <br /> and drop PDF file
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept="application/pdf"
                          id="upload-pdf-3"
                          style={{ minWidth: "100%" }}
                          multiple={false}
                          onChange={(e) => {
                            let check = checkFile(e.target);
                            if (check.status) {
                              setPdfFile1(e.target.files[0]);

                              setTimeout(() => {
                                setCompareShowPdfPrompt(true);
                              }, 300);
                            } else {
                              Swal.fire("Warning", check.message, "warning");
                            }
                          }}
                        />
                     
                        {pdfFile1 ? (
                          pdfFile1.name.length > 20 ? (
                            <p className="sub-para">{`${pdfFile1.name
                              .substring(20, 0)
                              .trim()}...`}</p>
                          ) : (
                            <p className="sub-para">{pdfFile1.name}</p>
                          )
                        ) : null}
                        <br />
                      </Form.Group>
                      <br />
                      <div>
                        <h6 className="note">
                          Note:-The PDF file(s) should consist of exactly 4000
                          words.{" "}
                        </h6>
                      </div>

                      <button
                        className={
                          showChatResponse || (!pdfFile0 && !pdfFile1)
                            ? "btn-disabled"
                            : ""
                        }
                        disabled={showChatResponse || (!pdfFile0 && !pdfFile1)}
                        onClick={() => {
                          if (
                            ((localStorage.getItem("plan") &&
                              JSON?.parse(localStorage.getItem("plan"))?.id) ||
                              globalDataRef.current.currentPlan?.id) ===
                            PREMIUM_PLAN_ID
                          ) {
                            setCompareShowPdfPrompt(true);
                            globalDataRef.current.setShowPopupModal(false);
                            handleQuerySubmit();
                          } else {
                            setUpgradePlanPopupModal(true);
                          }
                        }}
                      >
                        Go
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="right-chat-box-dropdown chat-agreement-area create-agreement-form-area other-feature-dropdown">
                <Dropdown autoClose={true}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic-id"
                    //   data-toggle="collapse"
                    //   data-target="#collapseThree"
                    //   aria-expanded="false"
                    //   aria-controls="collapseThree"
                    //  onClick={()=>setIsOfferingFeature({...isOfferingFeature, isCollapsedOne:false, isCollapsedTwo:false, isCollapsedThree:(!isOfferingFeature.isCollapsedThree)})}>
                  >
                    Create Agreement
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h6>Complete the form. Any field can be skipped.</h6>
                    <div className="chat-popup-area">
                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Identify Parties
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={50}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          placeholder="Specify involved parties"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                identifyPartie: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Define Purpose
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={150}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          placeholder="Define Purpose of the agreement"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                definePurpose: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Outline Obligations
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={150}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          placeholder="Define your obligations"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                outlineObligation: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Determine Consequences
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={150}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          placeholder="Determine possible outcomes"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                determineConsequences: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Specify Dates
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={50}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          placeholder="Starts today, ends in a week"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                specifyDates: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Identify Jurisdiction
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={100}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          placeholder="California, USA"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                identifyjurisdiction: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Determine Signing Method
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={100}
                          placeholder="Online or sign with pen"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            setcontractField((prevObj) => {
                              return {
                                ...prevObj,
                                signingMethod: e.target.value,
                              };
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                        />
                      </Form.Group>
                      <br />
                      <button
                        //  className={"btn-disabled"}
                        className={
                          !contractField.identifyPartie &&
                          !contractField.signingMethod &&
                          !contractField.determineConsequences &&
                          !contractField.definePurpose &&
                          !contractField.identifyjurisdiction &&
                          !contractField.outlineObligation &&
                          !contractField.specifyDates
                            ? "btn-disabled"
                            : ""
                        }
                        disabled={
                          !contractField.identifyPartie &&
                          !contractField.signingMethod &&
                          !contractField.determineConsequences &&
                          !contractField.definePurpose &&
                          !contractField.identifyjurisdiction &&
                          !contractField.outlineObligation &&
                          !contractField.specifyDates
                        }
                        onClick={() => {
                          if (
                            ((localStorage.getItem("plan") &&
                              JSON?.parse(localStorage.getItem("plan"))?.id) ||
                              globalDataRef.current.currentPlan?.id) ===
                            PREMIUM_PLAN_ID
                          ) {
                            setCompareShowPdfPrompt(true);
                            globalDataRef.current.setShowPopupModal(false);
                            startNewChat();
                            // createAgreement()
                            generateAgreement();
                          } else {
                            setUpgradePlanPopupModal(true);
                          }
                        }}
                      >
                        Go
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-area-box"
        show={globalDataRef.current.showContactUsModal}
        // show ={true}
        onHide={() => {
          globalDataRef.current.setShowContactUsModal(false);
          setContactUsFields({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "",
          });
          setContactUsFieldsErrors({
            firstName: false,
            lastName: false,
            email: false,
            message: false,
            phone: false,
          });
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes">
            <div className="left-chat-box">
              <div className="chat-history-header"></div>

              <div className="right-chat-box-dropdown">
                <div>
                  <Form onSubmit={handleContactUsSubmit}>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.firstName ? "border-red" : ""
                        }
                        placeholder="Enter First Name"
                        value={contactUsFields.firstName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              firstName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Last Name"
                        className={
                          contactUsFieldsErrors.lastName ? "border-red" : ""
                        }
                        value={contactUsFields.lastName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              lastName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE
                            ? COUNTRY_CODE?.toLocaleLowerCase()
                            : "us"
                        }
                        className={
                          contactUsFieldsErrors.phone
                            ? "border-red phone-input"
                            : "phone-input"
                        }
                        placeholder="Enter phone number"
                        value={contactUsFields.phone}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          if (e.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else if (e.trim().length < 10) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.email ? "border-red" : ""
                        }
                        placeholder="Enter Email"
                        value={contactUsFields.email}
                        maxLength={100}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              email: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else if (!EMAILREGEX.test(e.target.value)) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Message"
                        className={
                          contactUsFieldsErrors.message ? "border-red" : ""
                        }
                        value={contactUsFields.message}
                        maxLength={1000}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              message: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="formFile"
                      className="mb-2 captcha-size"
                    >
                      <ReCAPTCHA
                        sitekey="6LfaWWwmAAAAAMoBaomEA1S3cqBAp6Fqj-vrPQkk"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <br />
                    <button
                      className="try-ti-buton"
                      disabled={
                        contactUsFields.firstName &&
                        contactUsFields.lastName &&
                        contactUsFields.phone &&
                        contactUsFields.email &&
                        contactUsFields.message &&
                        isCaptchaValue
                          ? false
                          : true
                      }
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Chat;

import { Accordion, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import Pricing from "./Pricing";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import { DEFAULT_PLAN_ID, EMAILREGEX } from "../../config/config";
import { openAiService } from "../../services/openai.services";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";

const ct = require("countries-and-timezones");

const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];

const UpdateHome = () => {
    const [showloader, setShowloader] = useState(false)
    const navigate = useNavigate();
    const globalData = useContext(context);
    const location = useLocation();
    const pricingSectionRef = useRef();
    const [contactUsFields, setContactUsFields] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
    });
    const [isCaptchaValue, setIsCaptchaValue] = useState();
    const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        message: false,
    });

    function onChange(value) {
        setIsCaptchaValue(value);
        console.log("Captcha value:", value);
    }


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.email) {
            setContactUsFields((obj) => {
                return {
                    ...obj,
                    email: user.email,
                };
            });
        }
        if (globalData.scrollToPricing) {
            if (pricingSectionRef.current) {
                window.scrollTo(0, pricingSectionRef.current.offsetTop);
            }
            globalData.setScrollToPricing(false);
        }
    }, [globalData.scrollToPricing]);

    const handleScrollToSection = () => {
        window.scrollTo({
            top: pricingSectionRef.current.offsetTop,
            behavior: 'smooth'
        });
    };

    const handleContactUsSubmit = (e) => {
        e.preventDefault();
        let formValidated = true;
        if (contactUsFields.firstName.trim() === "") {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    firstName: true,
                };
            });
        }
        if (contactUsFields.lastName.trim() === "") {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    lastName: true,
                };
            });
        }
        if (contactUsFields.phone.trim() === "") {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    phone: true,
                };
            });
        } else if (contactUsFields.phone.trim().length < 10) {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    phone: true,
                };
            });
        }
        if (contactUsFields.email.trim() === "") {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    email: true,
                };
            });
        } else if (!EMAILREGEX.test(contactUsFields.email)) {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    email: true,
                };
            });
        }
        if (contactUsFields.message.trim() === "") {
            formValidated = false;
            setContactUsFieldsErrors((obj) => {
                return {
                    ...obj,
                    message: true,
                };
            });
        }
        if (formValidated) {
            let data = {
                first_name: contactUsFields.firstName,
                last_name: contactUsFields.lastName,
                email: contactUsFields.email,
                message: contactUsFields.message,
                phone: "+" + contactUsFields.phone,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };
            setShowloader(true)
            openAiService
                .contactUs(data)
                .then((res) => {
                    console.log("contact us api response", res);
                    setShowloader(false)
                    Swal.fire(
                        "Success",
                        "Thank you for submitting your request. We are reviewing it and will respond soon!",
                        "success"
                    ).then(() => {
                        globalData.setShowContactUsModal(false);
                        setContactUsFields({
                            firstName: "",
                            lastName: "",
                            email: "",
                            message: "",
                            phone: "",
                        });
                    });
                })
                .catch((err) => {
                    setShowloader(false)
                    Swal.fire("Error", "Something went wrong.", "error");
                    console.log("contact us api error", err);
                });
        }
    };
    return (
        <>
            {/* main hero banner starts */}
            <section className="udated-hero-section">
                <video className="updated-video" autoPlay muted loop playsInline preload='none' >
                    <source src={require("../../assests/images/header-main.mp4")} type="video/mp4" />
                </video>
                <Container fluid>
                    <Row>
                        <Col md={6}>
                            <div className="updated-content">
                                <h5>AI Attorney</h5>
                                <h1>Instant Access to Your Personal Legal Advisor
                                </h1>
                                <div className="action-btns">
                                    <Button
                                        onClick={() =>
                                            (localStorage.getItem("user") &&
                                                JSON.parse(localStorage.getItem("plan"))?.id !==
                                                DEFAULT_PLAN_ID) ?
                                                navigate("/legal-advisory")
                                                :
                                                globalData.setTriggerLogin(true)
                                        }
                                        className="get-your-btn"
                                    >
                                        Get trial ⟶
                                    </Button>
                                    <Button
                                        className="get-your-btn explore-style"
                                        onClick={handleScrollToSection}
                                    >
                                        Explore ⟶
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* main hero banner ends */}

            {/*  most powerfull starts */}
            <section className="powerfull-section">
                <Container>
                    <Row className="">
                        <Col md={4}>
                            <div className="powerfull-content">
                                <h2>Unlocking Legal Success: Virtual Assistant for All</h2>
                                <p>AI Attorney’s help people by providing access to required legal information and assistance. It simplifies the complex legal language and helps them get a better understanding of legal issues.</p>
                                <Button
                                    onClick={() =>
                                        (localStorage.getItem("user") &&
                                            JSON.parse(localStorage.getItem("plan"))?.id !==
                                            DEFAULT_PLAN_ID) ?
                                            navigate("/legal-advisory")
                                            :
                                            globalData.setTriggerLogin(true)
                                    }
                                    className="get-your-btn"
                                >
                                    Get trial ⟶
                                </Button>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="powerfull-img">
                                <img
                                    className="w-100"
                                    src={require("../../assests/images/unlockinglegal.png")}
                                    alt="img"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/*  most powerfull ends */}
            <span ref={pricingSectionRef}></span>
            <Pricing setScrollToPrice={location.state?.setScrollToPrice} />

            {/* slider blog preview starts */}
            <section className="slider-blog-section">
                <Container>
                    <div className="d-flex justify-content-between py-4 align-items-center">
                        <h2 className="subheading-text">The Latest</h2>
                        <a className="get-your-btn btn btn-primary" href="https://blogs.aiattorney.biz/" target="_blank">
                            View all ⟶
                        </a>
                    </div>
                    <div className="inner-blog-section">
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={'auto'}
                            coverflowEffect={{
                                rotate: 20,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                            }}
                            pagination={true}
                            modules={[EffectCoverflow, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/role-of-AI-for-lawyers.png")}
                                        alt="img"
                                    />
                                    <h2>8 Burning Questions About the Role of AI for Lawyers</h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/ai-for-in-house-legal-teams.png")}
                                        alt="img"
                                    />
                                    <h2>AI Revolution for In-House Legal Teams: 7 Headlines All Lawyers Need to Know</h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/ai-questions-for-lawyers.webp")}
                                        alt="img"
                                    />
                                    <h2>
                                        Answering the “Big Three” AI Questions for Lawyers: AI 101
                                    </h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/card-52.jpg")}
                                        alt="img"
                                    />
                                    <h2>Building the Future of Legal AI on a Strong Foundation </h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/role-of-AI-for-lawyers.png")}
                                        alt="img"
                                    />
                                    <h2>8 Burning Questions About the Role of AI for Lawyers</h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/ai-for-in-house-legal-teams.png")}
                                        alt="img"
                                    />
                                    <h2>AI Revolution for In-House Legal Teams: 7 Headlines All Lawyers Need to Know</h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/ai-questions-for-lawyers.webp")}
                                        alt="img"
                                    />
                                    <h2>
                                        Answering the “Big Three” AI Questions for Lawyers: AI 101
                                    </h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/card-52.jpg")}
                                        alt="img"
                                    />
                                    <h2>Building the Future of Legal AI on a Strong Foundation </h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/card-52.jpg")}
                                        alt="img"
                                    />
                                    <h2>Building the Future of Legal AI on a Strong Foundation </h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/role-of-AI-for-lawyers.png")}
                                        alt="img"
                                    />
                                    <h2>8 Burning Questions About the Role of AI for Lawyers</h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/ai-for-in-house-legal-teams.png")}
                                        alt="img"
                                    />
                                    <h2>AI Revolution for In-House Legal Teams: 7 Headlines All Lawyers Need to Know</h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="blog-card">
                                    <img
                                        className="w-100"
                                        src={require("../../assests/images/ai-questions-for-lawyers.webp")}
                                        alt="img"
                                    />
                                    <h2>
                                        Answering the “Big Three” AI Questions for Lawyers: AI 101
                                    </h2>
                                    <span className="blog-text">3 min read</span>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Container>
            </section>
            {/* slider blog preview ends */}

            {/* built starts */}
            <section className="build-section">
                <Container>
                    <h2 className="subheading-text mb-2">AI Solutions for Unparalleled Legal Performance</h2>
                    <p className="mb-3">AI Attorney’s virtual legal assistant platform not only assists people with legal matters but also offers many more functionalities. These features are added to help people with different aspects of legal matters.</p>
                    <Row className="align-items-center">
                        <Col md={5} lg={5}>
                            <Accordion defaultActiveKey="0">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#home">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>1. Quick legal Assistance</Accordion.Header>
                                                <Accordion.Body>
                                                Collaborating with an AI attorney can significantly streamline the process of obtaining legal information and advice.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#menu1">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>2. Draft in moment</Accordion.Header>
                                                <Accordion.Body>
                                                Effortlessly transform a blank page into a first draft in moments. Quickly modify tone, length, or positioning as needed. Create compelling arguments and high-quality contract clauses with exceptional speed, ease, and accuracy.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#menu2">
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>3. Documents Review</Accordion.Header>
                                                <Accordion.Body>
                                                    We provide you an easy-to-understand summary of complex legal documents in brief.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#menu3">
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>4. Document Comparison</Accordion.Header>
                                                <Accordion.Body>
                                                    Compare in detail and understand the differences in different legal documents.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </a>
                                    </li>

                                </ul>
                            </Accordion>
                        </Col>
                        <Col md={7} lg={6}>
                            <div class="tab-content text-right">
                                <div class="tab-pane container active" id="home">
                                    <div className="faq-img">
                                        <img
                                            className="w-75"
                                            src={require("../../assests/images/quick-legal.png")}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div class="tab-pane container fade" id="menu1">
                                    <div className="faq-img">
                                        <img
                                            className="w-75"
                                            src={require("../../assests/images/drafting-legal.png")}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div class="tab-pane container fade" id="menu2">
                                    <div className="faq-img">
                                        <img
                                            className="w-75"
                                            src={require("../../assests/images/documents-review.png")}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div class="tab-pane container fade" id="menu3">
                                    <div className="faq-img">
                                        <img
                                            className="w-75"
                                            src={require("../../assests/images/document-comparision.png")}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* built ends */}

            {/* Responsibly section starts */}
            <section className="responsibility-section">
                <div className="container">
                    <div className="row align-items-center">
                        <aside className="col-md-6">
                            <div className="responsibilty-img">
                                <img
                                    className=""
                                    src={require("../../assests/images/ai-software-for-lawyer.png")}
                                    alt="img"
                                />
                            </div>
                        </aside>
                        <aside className="col-md-6">
                            <div className="powerfull-content">
                                <h2 className="subheading-text">Smart AI for legal excellence
                                </h2>
                                <p>Are you a lawyer looking to increase your efficiency? If you are also tired of creating long documents and comparing all of them, the virtual legal assistant is the right solution for you. Now you can free up your time and focus on priority tasks such as engaging and representing clients, business development aspects, and building networks. Here is how we will help you:</p>
                                <ul className="list-points">
                                    <li>
                                        Performing all the legal research work
                                    </li>
                                    <li>
                                        Creating all legal documents
                                    </li>
                                    <li>
                                        Reviewing & comparing documents
                                    </li>
                                </ul>
                                <p>Not limited to this, we will also help you get AI-powered support for your legal law-firm website.</p>
                            </div>
                        </aside>
                    </div>
                    <div className="row margin-top60 align-items-center">
                        <aside className="col-md-6">
                            <div className="powerfull-content">
                                <h2 className="subheading-text">Why Choose Us for AI Legal Services?
                                </h2>
                                <p>AI Attorney is one of the leading AI lawyer bots, assisting people with the right and reliable legal services. Here is what makes us different from others:</p>
                                <ul className="list-points">
                                    <li>
                                        <p>
                                            <b>Quick</b> <br></br>
                                            AI-Attorney platform offers quick legal assistance and helps in drafting legal documents in less time.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Accessible</b> <br></br>
                                            Our AI-Attorney platform is easily accessible to customers, making it easy for them to get legal assistance, irrespective of location or time.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Secure</b> <br></br>
                                            Our AI-Attorney platform offers privacy, anonymity, and encryption to ensure that the client’s data is protected by seeking legal help.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Cost Effective
                                            </b> <br></br>
                                            The law industry is highly competitive. People could not afford continuous consultations, but ai tool for lawyers gives affordable legal services.
                                        </p>
                                    </li>
                                </ul>
                                <Button
                                    onClick={() =>
                                        (localStorage.getItem("user") &&
                                            JSON.parse(localStorage.getItem("plan"))?.id !==
                                            DEFAULT_PLAN_ID) ?
                                            navigate("/legal-advisory")
                                            :
                                            globalData.setTriggerLogin(true)
                                    }
                                    className="get-your-btn"
                                >
                                    Get trial ⟶
                                </Button>
                            </div>
                        </aside>
                        <aside className="col-md-6">
                            <div className="responsibilty-img">
                                <img
                                    className=""
                                    src={require("../../assests/images/why-choose.png")}
                                    alt="img"
                                />
                            </div>
                        </aside>
                    </div>
                </div>
            </section>
            {/* Responsibly section end */}

            {/* talk to one starts */}
            <section className="talk-section">
                <div className="container">
                    <div className="row">
                        <aside className="col-md-6">
                            <div className="powerfull-content-under-img position-relative">
                                <video className="content-video" autoPlay muted loop playsInline preload='none' >
                                    <source src={require("../../assests/images/aiattorney.mp4")} type="video/mp4" />
                                </video>
                            </div>
                        </aside>
                        <aside className="col-md-6">
                            <div className="powerfull-content">
                                <h2 className="subheading-text">Virtual Legal Assistant Business Opportunity!
                                </h2>
                                <p>AI tools for lawyers help students learn and practice legal and analysis skills. Students can get insight into the latest trends and issues in the legal industry and prepare for a career in law.</p>
                                <Button
                                    onClick={() =>
                                        (localStorage.getItem("user") &&
                                            JSON.parse(localStorage.getItem("plan"))?.id !==
                                            DEFAULT_PLAN_ID) ?
                                            navigate("/legal-advisory")
                                            :
                                            globalData.setTriggerLogin(true)
                                    }
                                    className="get-your-btn"
                                >
                                    Get trial ⟶
                                </Button>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>
            {/* talk to one ends */}
            <Modal
                className="modal-area-box"
                show={globalData.showContactUsModal}
                onHide={() => {
                    globalData.setShowContactUsModal(false);
                    setContactUsFields({
                        firstName: "",
                        lastName: "",
                        email: "",
                        message: "",
                        phone: "",
                    });
                    setContactUsFieldsErrors({
                        firstName: false,
                        lastName: false,
                        email: false,
                        message: false,
                        phone: false,
                    });
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                    <div className="right-chat-boxes">
                        <div className="left-chat-box">
                            <div className="chat-history-header"></div>
                            <div className="right-chat-box-dropdown">
                                <div>
                                    <Form onSubmit={handleContactUsSubmit}>
                                        <Form.Group controlId="formFile" className="mb-2">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                style={{ minWidth: "100%" }}
                                                multiple={false}
                                                className={
                                                    contactUsFieldsErrors.firstName ? "border-red" : ""
                                                }
                                                placeholder="Enter First Name"
                                                value={contactUsFields.firstName}
                                                maxLength={30}
                                                onChange={(e) => {
                                                    setContactUsFields((prevObj) => {
                                                        return {
                                                            ...prevObj,
                                                            firstName: e.target.value,
                                                        };
                                                    });
                                                    if (e.target.value.trim() === "") {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                firstName: true,
                                                            };
                                                        });
                                                    } else {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                firstName: false,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-2">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                style={{ minWidth: "100%" }}
                                                multiple={false}
                                                placeholder="Enter Last Name"
                                                className={
                                                    contactUsFieldsErrors.lastName ? "border-red" : ""
                                                }
                                                value={contactUsFields.lastName}
                                                maxLength={30}
                                                onChange={(e) => {
                                                    setContactUsFields((prevObj) => {
                                                        return {
                                                            ...prevObj,
                                                            lastName: e.target.value,
                                                        };
                                                    });
                                                    if (e.target.value.trim() === "") {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                lastName: true,
                                                            };
                                                        });
                                                    } else {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                lastName: false,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-2">
                                            <Form.Label>Phone Number</Form.Label>
                                            <PhoneInput
                                                country={
                                                    COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                                                }
                                                className={
                                                    contactUsFieldsErrors.phone
                                                        ? "border-red phone-input"
                                                        : "phone-input"
                                                }
                                                placeholder="Enter phone number"
                                                value={contactUsFields.phone}
                                                onChange={(e) => {
                                                    setContactUsFields((prevObj) => {
                                                        return {
                                                            ...prevObj,
                                                            phone: e,
                                                        };
                                                    });
                                                    if (e.trim() === "") {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                phone: true,
                                                            };
                                                        });
                                                    } else if (e.trim().length < 10) {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                phone: true,
                                                            };
                                                        });
                                                    } else {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                phone: false,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-2">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                style={{ minWidth: "100%" }}
                                                multiple={false}
                                                className={
                                                    contactUsFieldsErrors.email ? "border-red" : ""
                                                }
                                                placeholder="Enter Email"
                                                value={contactUsFields.email}
                                                maxLength={100}
                                                onChange={(e) => {
                                                    setContactUsFields((prevObj) => {
                                                        return {
                                                            ...prevObj,
                                                            email: e.target.value,
                                                        };
                                                    });
                                                    if (e.target.value.trim() === "") {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                email: true,
                                                            };
                                                        });
                                                    } else if (!EMAILREGEX.test(e.target.value)) {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                email: true,
                                                            };
                                                        });
                                                    } else {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                email: false,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-2">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows={3}
                                                style={{ minWidth: "100%" }}
                                                multiple={false}
                                                placeholder="Enter Message"
                                                className={
                                                    contactUsFieldsErrors.message ? "border-red" : ""
                                                }
                                                value={contactUsFields.message}
                                                maxLength={1000}
                                                onChange={(e) => {
                                                    setContactUsFields((prevObj) => {
                                                        return {
                                                            ...prevObj,
                                                            message: e.target.value,
                                                        };
                                                    });
                                                    if (e.target.value.trim() === "") {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                message: true,
                                                            };
                                                        });
                                                    } else {
                                                        setContactUsFieldsErrors((prevObj) => {
                                                            return {
                                                                ...prevObj,
                                                                message: false,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-2 captcha-size">
                                            <ReCAPTCHA
                                                sitekey="6LfaWWwmAAAAAMoBaomEA1S3cqBAp6Fqj-vrPQkk"
                                                onChange={onChange}
                                            />
                                        </Form.Group>
                                        <br />
                                        <button
                                            className="try-ti-buton"
                                            disabled={(contactUsFields.firstName && contactUsFields.lastName && contactUsFields.phone && contactUsFields.email && contactUsFields.message) && isCaptchaValue ? false : true}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default UpdateHome;
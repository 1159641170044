import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";

const Footer = () => {
  const navigate = useNavigate();
  const globalData = useContext(context);
  return <>
    <footer>
      <Container>
        <Row>
          {/* <Col md={4}>
            <div className="social-handle">
              <a href="">
                <img src={require("../../assests/images/insta.svg").default} alt="img" />
              </a>
              <a href="">
                <img src={require("../../assests/images/fac.svg").default} alt="img" />
              </a>
              <a href="">
                <img src={require("../../assests/images/twi.svg").default} alt="img" />
              </a>
            </div>
          </Col> */}
          <Col md={4} lg={6}>
            <p className="attorney-copyright">{`AI Attorney (c) ${new Date().getFullYear()}`}</p>
          </Col>
          <Col md={8} lg={6}>
            <div className="privacy-box">
              <span style={{'cursor': 'pointer'}} 
              onClick={()=>{
                navigate("/")
                globalData.setShowContactUsModal(true)
                }} className="privacy-content">Contact Us</span>
              <span style={{'cursor': 'pointer'}} onClick={()=>navigate("/privacy")} className="privacy-content">Privacy Policy</span>
              <span style={{'cursor': 'pointer'}} onClick={()=>navigate("/terms")} className="privacy-content">Term & Conditions</span>
              <a style={{'cursor': 'pointer'}} href="https://blogs.aiattorney.biz/" target="_blanck" className="privacy-content">Blog</a>
              <span style={{'cursor': 'pointer'}} onClick={()=>navigate("/cancellation-policy")} className="privacy-content">Cancellation Policy</span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </>;
};

export default Footer;

import axios from "axios"
import { baseUrl, openAiImageVariationUrl, OPENAI_ORGANIZATION_ID, OPENAI_SECRET_API_KEY, STRIPE_KEY } from "../config/config";

const jsonFileHeaders = {
    headers: {
        "Content-Type": "application/json",
        'Authorization':`Bearer ${OPENAI_SECRET_API_KEY}`,
        "OpenAI-Organization": OPENAI_ORGANIZATION_ID
    }
}

function jsonAuthHeaders () {
    const token = localStorage.getItem("token")
    return {"Authorization": `Token ${token}`}
}
// "Content-Type": "application/x-www-form-urlencoded",
const jsonStripeHeaders = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${STRIPE_KEY}`
    }
}

export const openAiService = {
    getChatResponse,
    imageVariation,
    registerUser,
    getChatHistory,
    bookmarkHistory,
    getBookmarkHistory,
    saveCard,
    getCards,
    buySubscription,
    listSubscriptions,
    comparePdf,
    getSubscription,
    updatePlan,
    getPlan,
    cancelSubscription,
    contactUs,
    deleteCard,
    createContract,
    generateContract,
    generateContractPdf,
    download_pdf,
    delete_History,
    getBookmark,
    userdetails,
    uploadPdfFiles,
    analysePdf,

}

function getChatResponse(params){
    return axios.post(`${baseUrl}/prompt/`, params, {headers:jsonAuthHeaders()});
}

function imageVariation(params){
    return axios.post(openAiImageVariationUrl, params, jsonFileHeaders);
}

function registerUser(params){
    return axios.post(`${baseUrl}/register/`, params);
}

function getChatHistory(page){
    return axios.get(`${baseUrl}/get_user_history?page=${page}`, {headers:jsonAuthHeaders()});
}

function bookmarkHistory(openaiId){
    return axios.post(`${baseUrl}/mark_prompt_as_bookmarked/${openaiId}`, {}, {headers:jsonAuthHeaders()});
}

function getBookmarkHistory(openaiId){
    return axios.get(`${baseUrl}/get_user_prompt/${openaiId}`, {headers:jsonAuthHeaders()});
}

function saveCard(params){
    return axios.post(`${baseUrl}/customer-payment-methods/`, params, {headers:jsonAuthHeaders()});
}

function getCards(){
    return axios.get(`${baseUrl}/customer-payment-methods/`, {headers:jsonAuthHeaders()});
}

function buySubscription(params){
    return axios.post(`${baseUrl}/create-subscription/`, params, {headers:jsonAuthHeaders()});
}

// function listSubscriptions(){
//     return axios.get(`https://api.stripe.com/v1/plans?active=true&product=prod_O9p22A5oocuqre`, jsonStripeHeaders);
// }

function listSubscriptions(){
    return axios.get(`https://api.stripe.com/v1/plans?active=true&product=prod_ODDCAMsOAlI6NR`, jsonStripeHeaders);
}


function getSubscription(subId){
    return axios.get(`https://api.stripe.com/v1/subscriptions/${subId}`, jsonStripeHeaders)
}

function updatePlan(id, params){
    return axios.post(`https://api.stripe.com/v1/plans/${id}`, params, jsonStripeHeaders);
}

function getPlan(id){
    return axios.get(`https://api.stripe.com/v1/plans/${id}`, jsonStripeHeaders);
}

function cancelSubscription(id, params){
    return axios.post(`${baseUrl}/cancel-subscription/${id}`, params, {headers:jsonAuthHeaders()});
}

function comparePdf(params){
    return axios.post(`${baseUrl}/get-pdf-content/`, params, {headers:jsonAuthHeaders()});
}

function uploadPdfFiles(params){
    return axios.post(`${baseUrl}/upload-pdf/`, params, {headers:jsonAuthHeaders()});
}

function contactUs(params){
    return axios.post(`${baseUrl}/contact-us/`, params);
}

function deleteCard(params){
    return axios.post(`${baseUrl}/delete_stripe_card/`, params, {headers:jsonAuthHeaders()});
}

function createContract(params){
    return axios.post(`${baseUrl}/create_contract/`, params, {headers:jsonAuthHeaders()});
}

function generateContract(params) {
    return axios.post(`${baseUrl}/generate-contract/`, params, {headers:jsonAuthHeaders()});
}

function generateContractPdf(params) {
    return axios.post(`${baseUrl}/generate-contract-pdf/`, params, {headers:jsonAuthHeaders()});
}

function download_pdf(openaiId){
    return axios.get(`${baseUrl}/download_pdf/${openaiId}`, {headers:jsonAuthHeaders()});
}

function delete_History(openaiId){
    return axios.delete(`${baseUrl}/delete_history_prompt/${openaiId}`, {headers:jsonAuthHeaders()});
}

function getBookmark(page){
    return axios.get(`${baseUrl}/get_user_prompts_bookmarked?page=${page}`, {headers:jsonAuthHeaders()});
}

function userdetails(){
    return axios.get(`${baseUrl}/my_detail/`, {headers:jsonAuthHeaders()});
}

function analysePdf(params){
    return axios.post(`${baseUrl}/upload-pdf-agreement/`, params, {headers:jsonAuthHeaders()});
}

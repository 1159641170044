import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { openAiService } from "../../services/openai.services";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
// const stripePromise = loadStripe('pk_test_jDwM5xMb4KtOZy6cevrR5LHv');  // staging
const stripePromise = loadStripe('pk_test_51NQmXOCh9FqMhX5azPQSBh7oXW8dSSGFbtk4y8OA2PVxW1Z7T2Jy334fvgmz8D2aMMP1qlLk5OplsHgme68P5Qgv00Gdndcg4S');

function CardDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const plan = location?.state?.plan;
  const [selectedCard, setSelectedCard] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [cancellationPolicyCheck, setCancellationPolicyCheck] = useState(false);
  const purchasedPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : ""
  const globalData = useContext(context);
  const [showDummyCardModal, setShowDummyCardModal] = useState(false)
  const [errors, setErrors] = useState({
    cancellationPolicyError: false,
  });
  const globalDataRef = useRef();
  globalDataRef.current = globalData;
  useEffect(() => {
    if (location.state.plan) {
      getCards(false)
    } else {
      globalDataRef.current.setScrollToPricing(true)
      navigate("/")
    }
  }, [])

  function getCards(selectCard) {
    setShowLoader(true)
    openAiService.getCards().then(res => {
      console.log("getcards response= ", res.data?.data);
      let cardsSortedByCreatedAt = res.data?.data.sort((a, b)=> Date(a.created) - Date(b.created))
      setCards(cardsSortedByCreatedAt);
      if(selectCard){
        setSelectedCard(cardsSortedByCreatedAt[0])
      }
      setShowLoader(false)
    }).catch(error => {
      console.log("error", error);
      setShowLoader(false)
      if (error?.response?.status === 401) {
        localStorage.clear()
        globalDataRef.current.setSignUpModal(true)
        navigate("/")
      }
    })
  }

  const [cards, setCards] = useState([]);
  const [showAddCard, setShowAddCard] = useState(false);

  function handleBuySubscription(e) {
    e.preventDefault();
    buySubscription()
  }
  function buySubscription() {
    if(!cancellationPolicyCheck){
      setErrors((obj)=>{
        return ({
          ...obj,
          cancellationPolicyError: true
        })
    })
    }else{
      setErrors((obj)=>{
        return ({
          ...obj,
          cancellationPolicyError: false
        })
    })
      if (selectedCard) {
        setShowLoader(true)
        let params = {
          plan_id: plan.id,
          payment_method: selectedCard.id
        };
        openAiService.buySubscription(params).then(res => {
          let user = JSON.parse(localStorage.getItem("user"))
          console.log("res---->",res)
          console.log("plan---before>",plan)
          // debugger
          user.subscription_plan = res.data.id
          localStorage.setItem("user", JSON.stringify(user))
          localStorage.setItem("plan", JSON.stringify(plan))
          console.log("plan--->",plan)
          // debugger
          globalDataRef.current.setCurrentPlan(plan)
          Swal.fire("Success", "Subscription purchased successfully", "success");
          globalDataRef.current.setPlanValidityExpired(false)
          localStorage.setItem("planExpired", false)
          navigate("/legal-advisory")
          setShowLoader(false)
        }).catch(error => {
          setShowLoader(false)
          console.log("error-->",error)
          Swal.fire("Error", error?.response?.data?.error, "error")
          if (error?.response?.status === 401) {
            localStorage.clear()
            globalDataRef.current.setSignUpModal(true)
            navigate("/")
          }
        })
      } else {
        Swal.fire("Warning", "Please choose a card.", "warning")
      }
    }
  }
  function hideForm() {
    setShowAddCard(false)
  }
  const dummyCardModal = () => {
    setShowDummyCardModal(true)
  }
  const cancellationLabel =  <span style={{'fontSize': '14px'}}>Accept Cancellation Policy <a target="_blank" href="/cancellation-policy">Click here for Cancellation Policy</a></span>

  /// delete card

  const deleteCard = (itemId) => {
    let params = {
      "card": itemId
    }
    setShowLoader(true)
    openAiService.deleteCard(params).then(res=>{
      setCards(cards.filter(item => item.id !== res.data.id))
      setShowLoader(false)
    }).catch(err=>{
      Swal.fire("Error", "Something went wrong.", "error")
      setShowLoader(false)
    })
  }
  
  return (
    <>
      {
        showLoader &&
      <div className="main-loader">
       <div className="loader3">
            <span></span>
            <span></span>
        </div>
        </div>      
        }
      <section className="pricing-area card-detail">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="pricing-top-heading-area">
                <h4>Buy Subscription Plan</h4>
                <p>Currently, this site is only in test mode. <span className="click-here-anchor" onClick={dummyCardModal}>Click here</span> to view the dummy card's details.</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col lg={3} md={6} className="mb-3">
              <div
                className="pricing-content-area selected-plan-box"

              >
                <div className="pricing-top">
                  <img
                    src={require("../../assests/images/pricing1.png")}
                    alt="img"
                  />
                </div>
                <h5>${plan?.amount / 100} /{plan?.interval}ly</h5>
                {plan?.metadata?.features!=undefined?
                <ul>
                  {
                    JSON.parse(plan.metadata?.features).map(feature => {
                      return (<>
                        <li>
                          {" "}
                          <img
                            src={require("../../assests/images/check.svg").default}
                            alt="img"
                          />{" "}
                          {feature}
                        </li>
                      </>)
                    })
                  }
                </ul>:null}
                <Form>
                <Form.Check className={errors.cancellationPolicyError ? "mb-3 signup-error terms-label" : "mb-3 terms-label"} type="checkbox" checked={cancellationPolicyCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setErrors(obj => {
                          return ({
                            ...obj,
                            cancellationPolicyError: false
                          })
                        })
                      }
                      setCancellationPolicyCheck(e.target.checked)
                    }}
                    label={cancellationLabel} />
                </Form>
                <button 
                  // disabled={!cancellationPolicyCheck} 
                  className={purchasedPlan && purchasedPlan?.id === plan.id && "curretn-plan-none"}
                  onClick={(e) => { handleBuySubscription(e) }}>
                  {purchasedPlan && purchasedPlan?.id === plan.id
                    ?
                    "Current Plan"
                    :
                    purchasedPlan?.amount < plan.amount
                      ?
                      <>
                        Upgrade Plan
                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      </>
                      :
                      <>
                        Purchase Plan
                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      </>
                  }
                </button>
              </div>
            </Col>
            <Col lg={5} md={6} className="mb-3">
              <div className=" payment-detail-area">
                <div className="payment-add-card">
                  <h4>Payment Method</h4>
                  {(!showAddCard && cards.length !== 0) &&
                    <div className="add-card">
                      <Button
                        type="button"
                        variant="unset"
                        onClick={() => setShowAddCard(!showAddCard)}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        Add New Card
                      </Button>
                    </div>
                  }
                </div>
                {(showAddCard || cards.length === 0) && (
                  <div className="form-detail-confirm-btn">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm func={getCards} hideForm={hideForm} />
                    </Elements>
                  </div>
                )}
                {cards.length > 0 &&
                  <div className="cards-images-area">
                    <div className="saved-view-card">
                      <h6>Saved Cards</h6>
                      {/* <p>View All</p> */}
                    </div>
                    <div className="saved-cards-area">
                      {cards.map((item, index) => {
                        return (
                          <div className={selectedCard?.id === item?.id ? "card1" : "card1"} onClick={() => {
                              if(selectedCard && selectedCard?.id === item?.id){
                                setSelectedCard()
                              }else{
                                setSelectedCard(item)
                              }
                            }} style={{ 'cursor': 'pointer' }}>
                            <div className="selected-card delete-card">
                              <i className="fa fa-trash" aria-hidden="true" onClick={()=>{deleteCard(item.id)}}></i>
                            </div>
                            {selectedCard?.id === item?.id ?
                              <>
                                <div className="selected-card">
                                  <i className="fa fa-check" aria-hidden="true"></i>
                                </div>
                              </>
                              : null
                            }
                            {/* <div className="selected-card delete-card">
                              <i className="fa fa-trash" aria-hidden="true" onClick={()=>{deleteCard(item.id)}}></i>
                            </div> */}
                             <div className="selected-card delete-card">
                              <i className="fa fa-trash" aria-hidden="true" 
                              // onClick={()=>{deleteCard(item.id)}}
                              onClick={(e) =>{
                                Swal.fire({
                                  icon: "info",
                                  html: `<div className="logout-text">
                                  Are you sure you want to delete this card?
                                  </div>
                                  `,
                                  showCloseButton: true,
                                  showConfirmButton: true,
                                  showCancelButton: true,
                                }).then((res) => {
                                  if (res.isConfirmed) {
                                    // DeleteDraft(item?.id)
                                    deleteCard(item.id)
                                  }
                                });
                              }}
                              ></i>
                            </div>
                            <div className="card-detail-box">
                              <p>{item.card?.brand}</p>
                              <p>{item.card?.exp_month}/{item.card?.exp_year}</p>
                            </div>

                            <div className="card-detail-box">
                              <p>XXXXXXXXXXXXXXX{item.card?.last4}</p>
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
      className="dummy-card-area"
        show={showDummyCardModal}
        backdrop="static"
        onHide={() => {
          setShowDummyCardModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="text-center pt-0">
          <div className="google-login-btn">
            <Form>
         
                <div className="login-form-box p-0 border-0">
                  <h5 className="mb-4">Dummy Card</h5>
                  <div className="card1 card1-dummy">
                    <div className="card-detail-box">
                      <p>VISA</p>
                      <div className="d-flex align-items-center">
                      <p>25/30</p>
                      <p className="cvv-txt ms-3">555</p>
                      </div>
                    </div>
                    <div className="card-detail-box">
                      <p>4242 4242 4242 4242</p>
                    </div>
                  </div>
                </div>
             
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CardDetail;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { APP_URL, CONTACT_US_EMAIL } from "../../config/config";

const Terms = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])
  const appUrl = APP_URL
  const contactUsEmail = CONTACT_US_EMAIL
  return (
    <>
      <section className="privacy-area">
        <Container fluid>
          <Row>
            <Col md={3} className="p-0">
            </Col>
            <Col md={6}>
                <div className="privacy-content">
                    <h2>Terms and Conditions for Use of {appUrl}</h2>
                    <p className="my-3">
                    Welcome to <strong className="d-inline">{appUrl}</strong>. By accessing this website, you agree to comply with and be bound by the following Terms and Conditions of use, which together with our Privacy Policy govern your relationship with {appUrl}. If you disagree with any part of these Terms and Conditions, please do not use our website.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">1. Informational Purposes Only</strong>-  The information contained on {appUrl} is for informational purposes only. The website is purely AI-based and does not represent any legal law firm under any jurisdiction. The platform is not a substitute for professional legal advice, and any information provided on the website is not intended to be, nor should it be construed as, legal advice.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">2. No Accountability of Registered Lawship</strong> -  {appUrl} is not a registered law firm under any jurisdiction. It does not hold accountability for any registered lawship, and any legal advice or representation sought through the website is solely at the user's discretion and responsibility.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">3. Use of AI Technology</strong> - {appUrl} uses AI technology to provide information and assistance related to law. However, we do not guarantee the accuracy, completeness, or reliability of any information provided on the website. The use of our website and any information obtained through it is solely at your own risk.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">4. Intellectual Property Rights</strong> -  The content of {appUrl}, including but not limited to text, graphics, images, logos, and software, is the property of {appUrl} and is protected by international copyright and trademark laws. You may not reproduce, copy, distribute, modify, or create derivative works of any content on the website without our prior written consent.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">5. No Attorney-Client Relationship</strong> - The use of the website does not create an attorney-client relationship. The website does not provide legal representation or advice. The information provided on the website is not a substitute for legal advice from a licensed attorney.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">6. Governing Law and Jurisdiction</strong> - These Terms and Conditions shall be governed by and construed in accordance with the laws of Punjab,India. Any disputes arising from or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in Punjab,India.
                    </p>
                    <p className="my-3">
                    <strong className="d-inline">7. Changes to Terms and Conditions</strong> -  {appUrl} reserves the right to change these Terms and Conditions at any time without notice. Your continued use of the website following any changes indicates your acceptance of the revised Terms and Conditions.
                    </p>
                    <p className="my-3">
                    Thank you for using <strong className="d-inline">{appUrl}</strong>. If you have any questions or concerns regarding these Terms and Conditions, please contact us at {contactUsEmail}
                    </p>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Terms;

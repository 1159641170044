export function getItem(type){
    return localStorage.getItem(type)?JSON.parse(localStorage.getItem(type)):false;
}

export function setItem(type,value){
    if(typeof(value) == "object"){
        localStorage.setItem(type,JSON.stringify(value));
    }else{
        localStorage.setItem(type,value);
    }

}

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import video from "../../assests/images/main-area-video.mp4"
import Pricing from "./Pricing";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import { DEFAULT_PLAN_ID, EMAILREGEX } from "../../config/config";
import { useRef } from "react";
import { openAiService } from "../../services/openai.services";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";

// import { useNavigate } from "react-router-dom";

const ct = require("countries-and-timezones");

const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];

const Home = () => {
  const [showloader, setShowloader]=useState(false)
  const navigate = useNavigate();
  const globalData = useContext(context);
  const location = useLocation();
  const pricingSectionRef = useRef();
  const [active, setActive]=useState(false)
  const [contactUsFields, setContactUsFields] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const [isCollapsed, setIsCollapsed] = useState({isCollapsedOne:false, isCollapsedTwo:false, isCollapsedThree:false, isCollapsedFour:false, isCollapsedFive:false});
  const [isImportant, setIsImportant]=useState({isCollapsedOne:false, isCollapsedTwo:false, isCollapsedThree:false, isCollapsedFour:false, isCollapsedFive:false});
  const [isOfferingFeature, setIsOfferingFeature]=useState({isCollapsedOne: false,  isCollapsedTwo:false, isCollapsedThree:false})

  function onChange(value) {
    // isCaptchaValue.current = value;
    // if((contactUsFields.firstName && contactUsFields.lastName && contactUsFields.phone && contactUsFields.email && contactUsFields.message)){
      setIsCaptchaValue(value);
    // }
    console.log("Captcha value:", value);
  }
  // useEffect( ()=>{
  //   if(localStorage.getItem("token")){
  //     try{
  //       axios.get("https://api.ipify.org?format=json").then(async(data)=> {
  //         console.log("data---", data?.data?.ip);
  //         await axios.get('https://ipinfo.io/json', {
  //           headers: {
  //             'Accept': 'application/json',
  //           }
  //         }).then(res=>{
  //             console.log("res---", res.json())
  //             debugger
  //           })
  //       })
  //     }catch{}
  //   }
  // }, [])
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.email) {
      setContactUsFields((obj) => {
        return {
          ...obj,
          email: user.email,
        };
      });
    }
    if (globalData.scrollToPricing) {
      if (pricingSectionRef.current) {
        window.scrollTo(0, pricingSectionRef.current.offsetTop);
      }
      globalData.setScrollToPricing(false);
    }
  }, [globalData.scrollToPricing]);
  const handleContactUsSubmit = (e) => {
    e.preventDefault();
    let formValidated = true;
    if (contactUsFields.firstName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          firstName: true,
        };
      });
    }
    if (contactUsFields.lastName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          lastName: true,
        };
      });
    }
    if (contactUsFields.phone.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    } else if (contactUsFields.phone.trim().length < 10) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (contactUsFields.email.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    } else if (!EMAILREGEX.test(contactUsFields.email)) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    }
    if (contactUsFields.message.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          message: true,
        };
      });
    }
    if (formValidated) {
      let data = {
        first_name: contactUsFields.firstName,
        last_name: contactUsFields.lastName,
        email: contactUsFields.email,
        message: contactUsFields.message,
        phone: "+" + contactUsFields.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
    setShowloader(true)
      openAiService
        .contactUs(data)
        .then((res) => {
          console.log("contact us api response", res);
          setShowloader(false)
          Swal.fire(
            "Success",
            "Thank you for submitting your request. We are reviewing it and will respond soon!",
            "success"
          ).then(() => {
            globalData.setShowContactUsModal(false);
            setContactUsFields({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
              phone: "",
            });
          });
        })
        .catch((err) => {
          setShowloader(false)
          Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err);
        });
    }
  };
  return (
    <>
      <section className="hero-banner">
        <div className="overlay-layer"></div>
            <video autoPlay muted loop playsInline  preload='none' >
              <source src={require("../../assests/images/main-area-video.mp4")} type="video/mp4" />
            </video>
          <div className="hero-content text-light text-center mt-3">
                <h1>
                  Instant Access to Your Personal <span><br></br>AI attorney</span>
                </h1>
                <p>
                  Tired with expensive consultations, lengthy appointment wait times, and the complexities of legal documents?  <br /> 
                  Experience the convenience of obtaining legal assistance from the AI Attorney team, available anytime and anywhere.
                </p>
                <button
                  onClick={() => 
                     (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("plan"))?.id !==
                      DEFAULT_PLAN_ID) ?
                      navigate("/legal-advisory")
                      :
                      globalData.setShowContactUsModal(true)
                  }
                  className="try-ti-buton animated-btn home-centered-btn"
                >
                  {localStorage.getItem("user") &&
                
                      JSON.parse(localStorage.getItem("plan"))?.id !==
                        DEFAULT_PLAN_ID && localStorage.getItem("user") !== null
                    ? "Get your legal advice"
                    : "Get in Contact"}
                </button>
              </div>
      </section>

     
      <section className="features-section common-padding">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="sub-heading">
                Unlocking Legal Success: Virtual Assistant for All
              </h2>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-box">
                <div className="feature-inner">
                  <span>1</span>
                  <div>
                    <h4>AI for People</h4>
                    <p>
                      AI patent attorneys help people by providing access to
                      required legal information and assistance. It simplifies
                      the complex legal language and helps them get a better
                      understanding of legal issues.
                    </p>
                  </div>
                </div>
                <img
                  src={require("../../assests/images/marketing-software-feature-1.webp")}
                  alt="img"
                />
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-box main-feature">
                <div className="feature-inner">
                  <span className="text-light">2</span>
                  <div>
                    <h4 className="text-light">AI for Lawyers</h4>
                    <p>
                      Artificial Intelligence lawyers save time by researching
                      and suggesting legal strategies. It also increases
                      efficiency, allowing attorneys to focus on high-level
                      tasks and enhancing client satisfaction.
                    </p>
                  </div>
                </div>
                <img
                  src={require("../../assests/images/marketing-software-feature-2.webp")}
                  alt="img"
                />
              </div>
            </Col>
            <Col lg={4} md={6} className="mb-3">
              <div className="feature-box">
                <div className="feature-inner">
                  <span>3</span>
                  <div>
                    <h4>AI for Law Students</h4>
                    <p>
                      AI tools for lawyers help students learn and practice
                      legal and analysis skills. Students can get insight into
                      the latest trends and issues in the legal industry and
                      prepare for a career in law.
                    </p>
                  </div>
                </div>
                <img
                  src={require("../../assests/images/marketing-software-feature-3.webp")}
                  alt="img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="call-to-action top-call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div className="call-to-action-left">
                <h4>Virtual Legal Assistant Business Opportunity!</h4>
                <p>Reach out to us to get a demo of the platform</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="call-to-action-right">
                <Button
                  className="try-ti-buton get-in-touch animated-btn"
                  onClick={() => globalData.setShowContactUsModal(true)}
                >
                  Get in Touch
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="advantage">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="NFTICALLYAdvantageContent">
                <h2 className="text-light">
                  Important Features AI Attorney Offers To You
                </h2>
                <p>
                  AI Attorney’s virtual legal assistant platform not only
                  assists people with legal matters but also offers many more
                  functionalities. These features are added to help people with
                  different aspects of legal matters.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="capabilities-section">
                <div id="accordionExample" className="accordion shadow">
                  {/* <!-- Accordion item 1 --> */}
                  <div className="card">
                    <div
                      id="headingOne"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          // className="btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isImportant.isCollapsedOne ? "collapsed" :" "}`}
                          onClick={()=>setIsImportant({...isImportant, isCollapsedOne:(!isImportant.isCollapsedOne), isCollapsedTwo:false, isCollapsedThree:false, isCollapsedFour:false})}
                        >
                          1. Quick Legal Assistance
                        </button>
                      </h2>
                    </div>
                  {isImportant.isCollapsedOne ?  <div
                      id="collapseOne"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          Our team is always there to assist you if you have any
                          queries or doubts.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 2 --> */}
                  <div className="card">
                    <div
                      id="headingTwo"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          // className="btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isImportant.isCollapsedTwo ? "collapsed" :" "}`}
                          onClick={()=>setIsImportant({...isImportant,isCollapsedOne:false, isCollapsedTwo:(!isImportant.isCollapsedTwo), isCollapsedThree:false, isCollapsedFour:false})}
                        
                        >
                          2. Drafting Legal Documents
                        </button>
                      </h2>
                    </div>
                  {isImportant.isCollapsedTwo ?  <div
                      id="collapseTwo"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          Artificial Intelligence lawyers help you create legal
                          documents in no minutes.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 3 --> */}
                  <div className="card">
                    <div
                      id="headingThree"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          // className="btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isImportant.isCollapsedThree ? "collapsed" :" "}`}
                          onClick={()=>setIsImportant({...isImportant,isCollapsedOne:false, isCollapsedTwo:false, isCollapsedThree:(!isImportant.isCollapsedThree),  isCollapsedFour:false})}
                          
                        >
                          3. Documents Review
                        </button>
                      </h2>
                    </div>
                   {isImportant.isCollapsedThree ? <div
                      id="collapseThree"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          We provide you an easy-to-understand summary of
                          complex legal documents in brief.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  {/* <!-- End --> */}

                  <div className="card">
                    <div
                      id="headingFour"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          // className="btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isImportant.isCollapsedFour ? "collapsed" :" "}`}
                          onClick={()=>setIsImportant({...isImportant,isCollapsedOne:false, isCollapsedTwo:false,isCollapsedThree:false, isCollapsedFour:(!isImportant.isCollapsedFour)})}
                        >
                          4. Document Comparison
                        </button>
                      </h2>
                    </div>
                  {isImportant.isCollapsedFour ?  <div
                      id="collapseFour"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          Compare in detail and understand the differences in
                          different legal documents.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                </div>
                {/* <!-- End --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="attorney-section common-padding">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={12}>
              <div className="atory-section">
                <h2 className="sub-heading text-left">
                  AI Software for Lawyers: The Benefits and Advantages
                </h2>
                <p>
                  Are you a lawyer looking to increase your efficiency? If you
                  are also tired of creating long documents and comparing all of
                  them, the virtual legal assistant is the right solution for
                  you. Now you can free up your time and focus on priority tasks
                  such as engaging and representing clients, business
                  development aspects, and building networks. Here is how we
                  will help you:
                </p>
                <ul>
                  <li>Performing all the legal research work</li>
                  <li>Creating all legal documents</li>
                  <li>Reviewing & comparing documents</li>
                </ul>
                <p className="mb-3">
                  Not limited to this, we will also help you get AI-powered
                  support for your legal law-firm website.
                </p>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="atory-img">
                <img
                  src={require("../../assests/images/education-new-1.png")}
                  alt="img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div className="call-to-action-left">
                <h4>
                  Are you looking for <span>AI Software</span> For Lawyers?
                </h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="call-to-action-right">
                <Button
                  className="try-ti-buton get-in-touch animated-btn"
                  onClick={() => globalData.setShowContactUsModal(true)}
                >
                  Reach Us
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span ref={pricingSectionRef}></span>
      <Pricing setScrollToPrice={location.state?.setScrollToPrice} />
      <section className="awesome-section common-padding">
        <Container>
          <Row className="mb-5">
            <Col md={12}>
              <h2 className="sub-heading mb-2 text-light">
                Why Choose Us for AI Legal Services?
              </h2>
              <p className="text-center">
                {/* AI Attorney is one of the leading ai lawyer bot which assisting
                people with the right & reliable legal services. Here is what
                makes us different from others: */}
                AI Attorney is one of the leading AI lawyer bots, assisting people
                 with the right and reliable legal services. Here is what makes us different from others:
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">⚡</span>
                <h4>Quick</h4>
                <p>
                  AI-Attorney platform offers quick legal assistance and helps
                  in drafting legal documents in less time.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">📱</span>
                <h4>Accessible</h4>
                <p>
                  Our AI-Attorney platform is easily accessible to customers,
                  making it easy for them to get legal assistance, irrespective
                  of location or time.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">🔐</span>
                <h4>Secure</h4>
                <p>
                  Our AI-Attorney platform offers privacy, anonymity, and
                  encryption to ensure that the client’s data is protected by
                  seeking legal help.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">💸</span>
                <h4>Cost Effective</h4>
                <p>
                  The law industry is highly competitive. People could not
                  afford continuous consultations, but ai tool for lawyers gives
                  affordable legal services.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="advantage advantage2 common-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <div className="NFTICALLYAdvantageContent">
                <h2 className="text-light text-center mb-4">FAQs</h2>
                <p></p>
              </div>
              <div className="capabilities-section">
                <div id="accordionExample" className="accordion shadow">
                  {/* <!-- Accordion item 1 --> */}
                  <div className="card">
                    <div
                      id="headingOne"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isCollapsed.isCollapsedOne ? "collapsed" :""}` }
                          onClick={()=>{setIsCollapsed({...isCollapsed,isCollapsedOne:(!isCollapsed.isCollapsedOne), isCollapsedTwo:false, isCollapsedThree:false, isCollapsedFour:false, isCollapsedFive:false});}}
                        >
                          1. What is AI attorney?
                        </button>
                        {console.log("??????????????????????????????????????",active)}
                      </h2>
                    </div>
                   {isCollapsed.isCollapsedOne ? <div
                      id="collapseOne"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                      className="collapse"
                      // className={isCollapsed ? "collapse show":"collapse" }
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          AI-Attorney is an innovative platform providing AI
                          legal advice and solutions at affordable prices.
                          People can easily understand complex legal documents
                          and do research in any language.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 2 --> */}
                  <div className="card">
                    <div
                      id="headingTwo"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isCollapsed.isCollapsedTwo ? "collapsed" :" "}`}
                          onClick={()=>setIsCollapsed({...isCollapsed,isCollapsedOne:false, isCollapsedTwo:(!isCollapsed.isCollapsedTwo),  isCollapsedThree:false, isCollapsedFour:false, isCollapsedFive:false})}
                        >
                          2. Who can benefit from the AI Attorney platform?
                        </button>
                      </h2>
                    </div>
                   {isCollapsed.isCollapsedTwo ? <div
                      id="collapseTwo"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          Common people, lawyers, and law students can benefit
                          from this platform. This platform helps them in
                          learning, researching, and drafting legal documents,
                          comparing complex documents, and practicing law.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 3 --> */}
                  <div className="card">
                    <div
                      id="headingThree"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isCollapsed.isCollapsedThree ? "collapsed" :" "}`}
                          onClick={()=>setIsCollapsed({...isCollapsed,isCollapsedOne:false,isCollapsedTwo:false,isCollapsedThree:(!isCollapsed.isCollapsedThree), isCollapsedFour:false, isCollapsedFive:false})}
                        >
                          3. What countries/languages does AI attorney support?
                        </button>
                      </h2>
                    </div>
                  {isCollapsed.isCollapsedThree ?  <div
                      id="collapseThree"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          AI-Attorney's bot is trained in different languages
                          and laws of different countries of the world. You can
                          specify your country and language and you will get the
                          desired results.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  {/* <!-- End --> */}

                  <div className="card">
                    <div
                      id="headingFour"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isCollapsed.isCollapsedFour ? "collapsed" :" "}`}
                          onClick={()=>setIsCollapsed({...isCollapsed,isCollapsedOne:false,isCollapsedTwo:false,isCollapsedThree:false,isCollapsedFour:(!isCollapsed.isCollapsedFour),isCollapsedFive:false,})}
                        >
                          4. How can you start working with AI Attorney?
                        </button>
                      </h2>
                    </div>
                   {isCollapsed.isCollapsedFour ? <div
                      id="collapseFour"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          You can start using the AI Attorney platform by
                          logging in using your Google account or other email
                          address. Next, you have to choose a plan and then you
                          can proceed further.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                  <div className="card">
                    <div
                      id="headingfive"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapsefive"
                          aria-expanded="false"
                          aria-controls="collapsefive"
                          className={`btn btn-link text-dark font-weight-bold text-uppercase collapsible-link ${!isCollapsed.isCollapsedFive ? "collapsed" :" "}`}
                          onClick={()=>setIsCollapsed({...isCollapsed,isCollapsedOne:false,isCollapsedTwo:false,isCollapsedThree:false,isCollapsedFour:false,isCollapsedFive:(!isCollapsed.isCollapsedFive)})}
                        >
                          5. Will AI replace lawyers?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedFive ?<div
                      id="collapsefive"
                      aria-labelledby="headingfive"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          No, artificial intelligence can never replace the
                          human brain. AI-Attorney is here to help people and
                          lawyers get the required assistance and do wonders in
                          their fields. It is better in assisting lawyers and
                          providing guidance.
                        </p>
                      </div>
                    </div>:null}
                  </div>
                </div>
                {/* <!-- End --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="modal-area-box"
        show={globalData.showContactUsModal}
        onHide={() => {
          globalData.setShowContactUsModal(false);
          setContactUsFields({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "",
          });
          setContactUsFieldsErrors({
            firstName: false,
            lastName: false,
            email: false,
            message: false,
            phone: false,
          });
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes">
            <div className="left-chat-box">
              <div className="chat-history-header"></div>
              <div className="right-chat-box-dropdown">
                <div>
                  <Form onSubmit={handleContactUsSubmit}>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.firstName ? "border-red" : ""
                        }
                        placeholder="Enter First Name"
                        value={contactUsFields.firstName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              firstName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Last Name"
                        className={
                          contactUsFieldsErrors.lastName ? "border-red" : ""
                        }
                        value={contactUsFields.lastName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              lastName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                        }
                        className={
                          contactUsFieldsErrors.phone
                            ? "border-red phone-input"
                            : "phone-input"
                        }
                        placeholder="Enter phone number"
                        value={contactUsFields.phone}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          if (e.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else if (e.trim().length < 10) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.email ? "border-red" : ""
                        }
                        placeholder="Enter Email"
                        value={contactUsFields.email}
                        maxLength={100}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              email: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else if (!EMAILREGEX.test(e.target.value)) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Message"
                        className={
                          contactUsFieldsErrors.message ? "border-red" : ""
                        }
                        value={contactUsFields.message}
                        maxLength={1000}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              message: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2 captcha-size">
                      <ReCAPTCHA
                        sitekey="6LfaWWwmAAAAAMoBaomEA1S3cqBAp6Fqj-vrPQkk"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <br />
                    <button
                      className="try-ti-buton"
                      disabled={(contactUsFields.firstName && contactUsFields.lastName && contactUsFields.phone && contactUsFields.email && contactUsFields.message)  && isCaptchaValue ? false : true}
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;

import { useState } from "react"
import { context } from "./GlobalContext"


const Context = (props) => {
    const [token, setToken] = useState("");
    const [showPopupModal, setShowPopupModal]=useState(localStorage.getItem("shPop") === "true" ? true : false)
    const [scrollToPricing, setScrollToPricing]=useState(localStorage.getItem("scPrice") === "true" ? true : false)
    const [planValidityExpired, setPlanValidityExpired]=useState(localStorage.getItem("planExpired"))
    const [currentPlan, setCurrentPlan] = useState();
    const [allSubPlans, setAllSubPlans] = useState();
    const [signUpModal, setSignUpModal] = useState(false);
    const [termsAndPrivacyModal, setTermsAndPrivacyModal] = useState(false);
    const [triggerLogin, setTriggerLogin] = useState(false)
    const [loginVia, setLoginVia] = useState("");
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    
    var data={
        showPopupModal,setShowPopupModal,
        scrollToPricing, setScrollToPricing, 
        planValidityExpired, setPlanValidityExpired, 
        currentPlan, setCurrentPlan, 
        allSubPlans, setAllSubPlans, 
        signUpModal, setSignUpModal,
        termsAndPrivacyModal, setTermsAndPrivacyModal,
        triggerLogin, setTriggerLogin,
        loginVia, setLoginVia,
        token, setToken,
        showContactUsModal, setShowContactUsModal
    }
    return (
        <context.Provider value={data}>
            {props.children}
        </context.Provider>
    );
}
export default Context;
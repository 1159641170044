import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { APP_URL } from "../../config/config";
// import CancellationPolicy from './CancellationPolicy';

const Cancellation = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])
  const appUrl = APP_URL
  return (
    <>
      <section className="login-area">
        <Container fluid>
          <Row>
            <Col md={3} className="p-0">
            </Col>
            <Col md={6}>
                <div className="privacy-content">
                    <h2>Cancellation and Refund Policy:</h2>
                    <p>
                    1. <strong>Subscription Cancellation</strong>- You may cancel your subscription to {appUrl} at any time from your account. Please note that canceling your subscription will immediately terminate access to any premium features or services associated with your subscription.
                    </p>
                    <p>
                    2. <strong>No Refunds</strong> -  Once a subscription payment has been processed, it is non-refundable. This means that if you cancel your subscription, you will not receive any refund for the remaining time left on your subscription.
                    </p>
                    <p>
                    3. <strong>Exceptional Circumstances</strong> - In exceptional circumstances, we may, at our discretion, offer a refund on a case-by-case basis. Such circumstances may include technical issues that prevent access to the service, or service outages that result in significant loss of functionality for a prolonged period of time.
                    </p>
                    <p>
                    4. <strong>Changes to Subscription Plans</strong> -  We may change the features, pricing, and subscription plans offered on {appUrl} at any time without notice. If we change the terms of your subscription plan, we will provide you with notice and an opportunity to cancel your subscription or switch to a new plan.
                    </p>
                    <p>
                    5. <strong>Termination</strong> - We reserve the right to terminate your subscription to {appUrl} at any time for any reason, including but not limited to violation of these terms and conditions or any applicable laws or regulations.
                    </p>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Cancellation;

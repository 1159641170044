import React, { useContext } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import { openAiService } from "../../services/openai.services";
import { context } from '../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useState } from 'react';

export default function CheckoutForm(props) {
  const globalData = useContext(context);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [cardStatus, setCardStatus]=useState(false)

  const handleSubmit = async (event) => {
    // debugger
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    event.preventDefault();
    if(cardStatus){
      return
    }
    setCardStatus(true)
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      // console.log(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
    //   stripeTokenHandler(result.token);
    // console.log("result token----",result.token);
    let cardObj = {
        card_token : result.token?.id
    };
    console.log("cardObj--->",cardObj)
        // debugger
        openAiService.saveCard(cardObj).then(res=>{
          cardObj ={card_token :""}
          setCardStatus(false)
            props.hideForm()
            props.func(true)
            }).catch(error=>{
            console.log("error",error);
            setCardStatus(false)
            if(error?.response?.status === 401){
                localStorage.clear()
                globalData.setSignUpModal(true)
                navigate("/")
            }else if(error?.response?.status === 400){
              Swal.fire("Error", error.response?.data?.error, "error")
            }else{
              Swal.fire("Error", "Something went wrong.", "error")
            }
        })

    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <button disabled={!stripe}>Save Card</button>
    </form>
  );
}